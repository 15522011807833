import React from "react";
import { GrFacebookOption } from "react-icons/gr";
import { PiMessengerLogoBold } from "react-icons/pi";
import { TbWorldWww } from "react-icons/tb";
import {
    FaWhatsapp,
    FaInstagram,
    FaLinkedinIn,
    FaTwitter,
    FaYoutube,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import footerLogo from "../assets/img/footer-logo.png";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
const socialData = [
    {
        id: 7,
        link: "https://accuratess.com/",
        color: "#3b5998",
        onHoverColor: "red",
        name: "Accurate",
        icon: TbWorldWww,
    },
    {
        id: 0,
        link: "https://www.facebook.com/AccurateSmartSolutions/",
        color: "#3b5998",
        onHoverColor: "red",
        name: "Facebook",
        icon: GrFacebookOption,
    },
    {
        id: 1,
        link: "https://m.me/245399558968790",
        color: "#0099FF",
        onHoverColor: "#0099FF",
        name: "Messenger",
        icon: PiMessengerLogoBold,
    },
    {
        id: 2,
        link: "https://wa.me/+201063825242",
        color: "#28D146",
        onHoverColor: "red",
        name: "Whatsapp",
        icon: FaWhatsapp,
    },
    {
        id: 3,
        link: "https://www.instagram.com/accurate_smart_solutions",
        color: "#e33f7a",
        onHoverColor: "red",
        name: "Instagram",
        icon: FaInstagram,
    },
    {
        id: 4,
        link: "https://twitter.com/AccUrate_ss",
        color: "#0072c5",
        onHoverColor: "#0072b1",
        name: "Twitter",
        icon: FaTwitter,
    },
    {
        id: 5,
        link: "https://www.linkedin.com/company/accurate-smart-solutions/",
        color: "#0072b1",
        onHoverColor: "#0072b1",
        name: "Linkedin",
        icon: FaLinkedinIn,
    },
    {
        id: 6,
        link: "https://www.youtube.com/@AccurateSmartSolutions",
        color: "red",
        onHoverColor: "#0072b1",
        name: "Youtube",
        icon: FaYoutube,
    },
];

export default function Contact() {
    const { t } = useTranslation();

    return (
        <div className="bg-primary" style={{ direction: "ltr" }}>
            <div className="footer-logo pt-4" style={{ paddingLeft: "80px" }}>
                <img src={footerLogo} alt="logo" height={"70px"} />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <h2 className=" text-white" style={{ fontSize: "xx-large" }}>
                    Accurate Smart Solutions
                </h2>
                <div className="m-4">
                    <h3 className=" text-white">
                        {t(
                            " we are a leading company in designing and developing financial and business software since 1996"
                        )}
                    </h3>
                </div>
                <div className="mb-5" style={{ width: "70%", maxWidth: "600px" }}>
                    {socialData.map((ele) => (

                        <a
                            href={ele.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={ele.id}
                            className="socialContact "
                        >
                            <div className="d-flex align-items-center ">
                                <ele.icon
                                    style={{
                                        fontSize: "25px",
                                        marginInline: "20px",
                                        color: ele.color,
                                    }}
                                />
                                <h5 className="m-0">{ele.name}</h5>
                            </div>
                        </a>
                    ))}
                    <div className="justify-content-center">
                        <Link
                            to={{ pathname: `/contact` }}
                            className="btn-primary btn-lg rounded-pill"
                        >
                            <div className="titleWrapper">
                                <p className="title p-2"> {"Request your trial demo"}{<IoIosArrowForward />} </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
