import React from "react";
import SectionWrapper from "../CustmComponent/SectionWrapper";

 import "../AccurateLogistics/AccurateLogistics.css"
 import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay,Mousewheel } from 'swiper';
function ClientSection(props) {
    const { BgColor, title, subTitle, link, id,img,textcolor, BgunderLine, dataArr} = props;


  return (
 <>
 
    <SectionWrapper
      BgColor={BgColor}
      id="clients-container"
      title={"CLIENTS' REVIEWS"}
      textcolor={textcolor}
      BgunderLine={BgunderLine}
    >
 <div className="container my-5">
 <div  style={{ direction:"ltr" }}>
        <Swiper
        loop={true}
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
          }}
         
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper customrReview"
         >
 {dataArr.map((data, index) => (
              <SwiperSlide style={{background:"var(--primaryColor)"}}  > 
                <div className="card" style={{width:"85%"}}>
              <div  className="card-body p-4">
            <div className="card-header d-flex" >
              <div>
              <img src={data.logo} alt="Avatar"  style={{ verticalAlign:" middle",
  width:" 80px",
  height: "80px",
  borderRadius:"50%"}}/>
  </div>
 
 <div>
                <h4>{data.name}</h4>
                <p>{data.companyName}</p></div>
           </div>   </div>
              <div className="card-body">
              <p style={{overflow:"scroll",height:"100px"}}>   {data.review}
</p>
              
             
              </div>
            </div>
            </SwiperSlide>
            ))}
          
        </Swiper>
</div>

 </div>
     
    </SectionWrapper>
  
    
    
    </>
  );
};

export default ClientSection;