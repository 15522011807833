import React from 'react'
import img1 from "../../assets/img/png/2023-08-06_13-47-43_dell-xps13-front.png";
import img2 from "../../assets/img/png/m.jpg";
import img3 from "../../assets/img/png/2.jpg";
import img4 from "../../assets/img/png/accounts.jpg";
import img5 from "../../assets/img/png/product.jpg";
import img6 from "../../assets/img/png/product.jpg";
import img7 from "../../assets/img/png/money.jpg";
import img8 from "../../assets/img/png/hr.jpg";
import img9 from "../../assets/img/png/hr.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import ParagraphText from "../CustmComponent/ParagraphText";
import { FiUsers } from "react-icons/fi";
import {
  RiBankLine,
  RiEBike2Line,
  RiMoneyDollarCircleLine,
  RiRoadMapLine,
} from "react-icons/ri";
import { Pagination, Navigation } from "swiper";
import "./AccurateErp.css";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineAccountCircle, MdOutlineIntegrationInstructions } from "react-icons/md";
import { VscPackage } from "react-icons/vsc";
import { AiOutlineWhatsApp } from "react-icons/ai";
import {
  BiPhoneOutgoing,
  BiBarcodeReader,
  BiTransferAlt,
} from "react-icons/bi";
import { BsChatDots, BsCart3 } from "react-icons/bs";
import CustmButton from "../CustmComponent/Custmbutton";
import AnimationPage from "../CustmComponent/AnimationPage";
import { erpAttribute2, erpAttribute3, erpAttribute4, erpAttribute5, erpAttribute6, erpAttribute7 } from "./AccurateErpData"
import { FaRegFileExcel, FaSortAmountUpAlt, FaStore } from 'react-icons/fa';
import { PiMicrosoftExcelLogoLight } from 'react-icons/pi';
import { TbReportAnalytics } from "react-icons/tb";

export const AccurateErp = () => {

  const { t } = useTranslation();
  const logisticFeature = [
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img4,
    },
    {
      img: img5,
    },
    {
      img: img6,
    },
    {
      img: img7,
    },
    {
      img: img8,
    },
    {
      img: img9,
    },
  ];

  const erpAttribute = [
    {
      icon: MdOutlineAccountCircle,
    },
    {
      icon: FaStore,
    },
    {
      icon: FaSortAmountUpAlt,
    },
    {
      icon: PiMicrosoftExcelLogoLight ,
    },
    {
      icon: FaRegFileExcel,
    },
    {
      icon: TbReportAnalytics,
    },
    // {
    //   icon: BiPhoneOutgoing,
    // },
    // {
    //   icon: BiBarcodeReader,
    // },
    // {
    //   icon: BsChatDots,
    // },
    // {
    //   icon: MdOutlineIntegrationInstructions,
    // },
    // {
    //   icon: BsCart3,
    // },
    // {
    //   icon: RiMoneyDollarCircleLine,
    // },
    // {
    //   icon: BiTransferAlt,
    // },
    // {
    //   icon: RiBankLine,
    // },
  ];

  const youtubevido = [
    { src: "https://www.youtube.com/embed/stc-FlYV6TA?si=90UExo-zn9Z5Yt_V" },
    { src: "https://www.youtube.com/embed/uCDWE3gIyOo?si=MU2IUoyQ_6b42Che" },
    { src: "https://www.youtube.com/embed/44kblFwaIGY?si=5VpvbwWDhVhkupWB" },
    { src: "https://www.youtube.com/embed/-my5W0bLNzc?si=JbLDthy6E8usWDjC" },
    { src: "https://www.youtube.com/embed/tLKYjsjeag4?si=_Hf9fj5oGLVwmxTN" }
  ];
  return (
    <AnimationPage>
      <SectionWrapper
        BgColor={"#fff"}
        title={t("projectErp.projname")}
        fontSize="fs-1"
        subTitle={t("projectErp.projdes")}
        extraClass={true}
      >
        <div className="container">
          {logisticFeature.map((data, index) => (
            <div
              key={index}
              className={`row align-items-center justify-content-between mb-4 g-3 ${!(
                index % 2
              ) && "flex-row-reverse"}`}
            >
              <div className="col-xl-6 mt-lg-0 col-12">
                <h3>{t(`erpFeature.${index}.title`)}</h3>
                <ParagraphText text={t(`erpFeature.${index}.body`)} />
              </div>
              <div className="col-xl-5 col-12 pe-xl-0 text-center">
                <img
                  src={data.img}
                  alt="aboutImg"
                  style={{ maxHeight: "450px" }}
                />
              </div>
            </div>
          ))}
          <div className="justify-content-center my-5">
            <CustmButton btnName={t("Request your trial demo")} />
          </div>
          <div
            className={`row align-items-center justify-content-between g-3}`}
          >
            <div className={`erptitle m-4 text-capitalize fs-2 d-flex justify-content-center`} >
              {t(`erpTitles.title`)}
            </div>
            {/* <h4 className="justify-content-center my-5">
              {t(`erpTitles.title`)}
            </h4> */}
            {erpAttribute.map((data, index) => (
              <>
                <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                  <data.icon
                    style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                  ></data.icon>
                  <ParagraphText text={t(`erpAttribute.${index}.body`)} />
                </div>
              </>
            ))}
            <div className={`erptitle m-4 text-capitalize fs-2 d-flex justify-content-center`} >
              {t(`erpTitles.title2`)}
            </div>
            {erpAttribute2.map((data, index) => (
              <>
                <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                  <data.icon
                    style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                  ></data.icon>
                  <ParagraphText text={t(`erpAttribute2.${index}.body`)} />
                </div>
              </>
            ))}
            <div className={`erptitle m-4 text-capitalize fs-2 d-flex justify-content-center`} >
              {t(`erpTitles.title3`)}
            </div>
            {erpAttribute3.map((data, index) => (
              <>
                <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                  <data.icon
                    style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                  ></data.icon>
                  <ParagraphText text={t(`erpAttribute3.${index}.body`)} />
                </div>
              </>
            ))}
            <div className={`erptitle m-4 text-capitalize fs-2 d-flex justify-content-center`} >
              {t(`erpTitles.title4`)}
            </div>
            {erpAttribute4.map((data, index) => (
              <>
                <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                  <data.icon
                    style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                  ></data.icon>
                  <ParagraphText text={t(`erpAttribute4.${index}.body`)} />
                </div>
              </>
            ))}
            <div className={`erptitle m-4 text-capitalize fs-2 d-flex justify-content-center`} >
              {t(`erpTitles.title5`)}
            </div>
            {erpAttribute5.map((data, index) => (
              <>
                <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                  <data.icon
                    style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                  ></data.icon>
                  <ParagraphText text={t(`erpAttribute5.${index}.body`)} />
                </div>
              </>
            ))}
            <div className={`erptitle m-4 text-capitalize fs-2 d-flex justify-content-center`} >
              {t(`erpTitles.title6`)}
            </div>
            {erpAttribute6.map((data, index) => (
              <>
                <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                  <data.icon
                    style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                  ></data.icon>
                  <ParagraphText text={t(`erpAttribute6.${index}.body`)} />
                </div>
              </>
            ))}
            <div className={`erptitle m-4 text-capitalize fs-2 d-flex justify-content-center`} >
              {t(`erpTitles.title7`)}
            </div>
            {erpAttribute7.map((data, index) => (
              <>
                <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                  <data.icon
                    style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                  ></data.icon>
                  <ParagraphText text={t(`erpAttribute7.${index}.body`)} />
                </div>
              </>
            ))}
          </div>
        </div>
        <div style={{ direction: "ltr" }}>
          <Swiper
            loop={true}
            slidesPerView={1}
            spaceBetween={10}
            style={{ height: "320px" }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            className="mySwiper videoSlider"
          >
            {youtubevido.map((data, index) => (
              <SwiperSlide>
                {" "}
                <div key={index} style={{ width: "90%", height: "100%" }}>
                  <iframe
                    width="100%"
                    height="250"
                    src={data.src}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    style={{ zIndex: "0" }}
                  ></iframe>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </SectionWrapper>
    </AnimationPage>
  )
}
