import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { Link } from "react-router-dom";

function SectionWrapper(props) {
  const {
    BgColor,
    Bg,
    title,
    subTitle,
    link,
    id,
    fontSize,
    extraClass,
    textcolor,
    BgunderLine,
  } = props;
  return (
    <div
      className="SectionWrapper py-2 "
      style={{ background: `${BgColor}` }}
      id={id}
    >
      {(title || subTitle) && (
        <div
          className={`d-flex justify-content-center flex-column align-items-center my-5`}
        >
          {title && link ? (
            <Link to={`${link}`}>{title}</Link>
          ) : (
            <div
              className={`title mb-2 text-capitalize ${
                fontSize ? fontSize : "fs-2"
              } ${textcolor ? textcolor : "text-primary"}`}
            >
              {title}
            </div>
          )}
          {BgunderLine && (
            <div
              style={{
                background: `${BgunderLine}`,
                height: "8px",
                width: "45px",
                margin: "auto",
                borderRadius: "20px",
              }}
            ></div>
          )}
          {subTitle && (
            <p
              className={`subTtitle p-0 px-5 text-center fs-5 ${
                extraClass ? "logisticSubtitle" : ""
              }`}
            >
              {subTitle}
            </p>
          )}
        </div>
      )}
      <div className="py-5" style={{ background: `${Bg}` }}>
        {props.children}
      </div>
    </div>
  );
}

SectionWrapper.propTypes = {
  BgColor: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fontSize: PropTypes.string,
};

export default SectionWrapper;
