import React from "react";
import PropTypes from "prop-types";
import "./style.css";

function ParagraphText(props) {
  const { text } = props;

  return <p className="ParagraphText">{text}</p>;
}

ParagraphText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ParagraphText;
