import React from "react";
import vision from "../../assets/img/vision.webp";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
 import PannerSection from"./PannerSection";
  import AdvangeSection from "./AdvangesSection"
 import AboutUsSection from"./AboutusSection";
 import ClientSection from "./ClientReview";
 import advange1 from "../../assets/img/advange1.svg"
 import advange2 from "../../assets/img/advange2.svg"
 import advange3 from "../../assets/img/advange3.svg"
 import advange4 from "../../assets/img/advange4.svg"
 import advange5 from "../../assets/img/advange5.svg"
 import advange6 from "../../assets/img/advange6.svg"
 import img1 from "../../assets/img/clients-logo/logo/IBCourier.png"
 import img2 from "../../assets/img/clients-logo/logo/anytime.png"
 import img3 from "../../assets/img/clients-logo/logo/telegraph.png"
 import img4 from "../../assets/img/clients-logo/logo/bareedy.png"
 import img5 from "../../assets/img/clients-logo/logo/ma.png"
 import img6 from "../../assets/img/clients-logo/logo/next.png"
 import img7 from "../../assets/img/clients-logo/logo/Thunder.png"
 import img8 from "../../assets/img/clients-logo/logo/eb3at.png"
 import BrandCarouselTwo from "../Brands/BrandCarouselTwo";
 import CustmButton from "../CustmComponent/Custmbutton";
 import "../AccurateLogistics/AccurateLogistics.css"
 import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay,Mousewheel } from 'swiper';
const AboutPage = () => {
  const { t } = useTranslation();
  const Advantages = [
    {
      img:advange1, 
      desc:"Commitment on time"
    },
    {
      img:advange2,
      desc:"Money Back Guarantee"
    },
    {
      img: advange3,
      desc:"Effective Partnerships"
    },
    {
      img: advange4,
      desc:"Expert Consultation"
    },
    {
      img: advange5,
      desc:"Transparent Communication"
    },
    {
      img: advange6,
      desc:"Result Oriented Solutions"
    },

  ];
 const  ClientReview=[
    { 
      name:" Ibrahim Dasuqi",
      companyName:"IB Courier Express ",
      review:"شركة محترمة جدا ومتابعه طول الوقت وأشكر الباشمهندس خالد علوان على اهتمامه بالعملاء تقبلو تحياتي ",
      logo:img1,
    },
  
    {
      name:"Soheib saad",
      companyName:"Any Taime ",
      review:"بالتوفيق والنجاح من أفضل شركات البرمجه الى اشتغلت معاهم وفريق عمل يستحق التقدير ",
      logo:img2,
    },
    {
      name:" AbdElaziz El Qbany ",
      companyName:"Telegraph",
      review:"شركة برمجه ممتازين مع خدمة ممتازة موصى به للغاية شكرا جزيلا الباشمهندس خالد على جهودك  أتمنى لك كل التوفيق  ",
      logo:img3,
    },
    {
      name:" Ahmed Bareedy ",
      companyName:"Bareedy Express ",
      review:"بكل صدق انتوا فعلا ونعم الناس والبرنامج بتاعكم سهل عليا حاجات كتييييره جدا من سهولته.. شكرا اكيوريت بالتوفيق ولو ف اي شركه شغاله انصحها بالبرنامج لانه قوي جدا  ",
      logo:img4 ,
    },
    {
      name:" Magdy khaled ",
      companyName:"M&A Express",
      review:"♥️احسن واسهل سيستم اتعاملنا معاه♥️ شكرا جدا للجوده الممتازه دي",
      logo:img5,
    }, 
     {
      name:"shref salem ",
      companyName:"Next Express EG",
      review:"شكرا جدا لتعاونكم معنا بجد السيستم جميل جدا و خدمة ما بعد البيع مفيش احسن من كده لما بحتاج اي حاجه او استفسار بيكون الرد السريع شكرا جدا ",
      logo: img6,
    },
    {
      name:"Ahmed Okasha ",
      companyName:"Thander Express",
      review:" انا حابب اشكر كل أسره اكيورت على الإضافة القويه دى لينا ومن وقت ما اتكلمنا عشان نستخدم السيستم والابليكيشن وكل حاجه كنا محتاجينها بنلاقيها فى المنتج بتاعهم وأسلوب سهل فى التعامل وفى تلقى الطلبات والتدريب  شكرا بجد وربنا يوفقنا كلنا ❤️ومن نجاح لنجاح إن شاء الله  ",
      logo:img7,
    },
    {
      name:"Youssef Elqady ",
      companyName:"Eb3at Express ",
      review:"❤️ شكرا جزيلًا لكم ونتمني لكم ايضا دوام النجاح والتقدم  ",
      logo:img8,
    },

  ]


  return (
 <>
     <PannerSection/>
    
    <AboutUsSection   BgColor={"#fafafa"} img={vision} title={t(`about2.title`)}  subTitle={t(`aboutPage.sectionaboutBody`)}/>
    <AdvangeSection
      BgColor={"var(--primaryColor)"}
      id="#"
      title={"Our Advantages"}
      textcolor={"text-white"}
      BgunderLine={"#fff"}
      dataArr={Advantages}
    ></AdvangeSection>
    <  BrandCarouselTwo />
    <ClientSection
      BgColor={"var(--primaryColor)"}
      id=""
      title={"CLIENTS' REVIEWS"}
      textcolor={"text-white"}
      BgunderLine={"#fff"}
      dataArr={ClientReview}
    ></ClientSection>
    <SectionWrapper
      id=""
      title={t("contactus")}
      subTitle={t("contactusSection")}
    >
   <div className="justify-content-center">
 < CustmButton btnName={t("Request your trial demo")} />

    </div>
     
    </SectionWrapper>
  
    </>
  );
};

export default AboutPage;