import { Link } from "react-router-dom";
import teamTwoData from "./servicesData";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import CustomLinkText from "../CustmComponent/CustomLinkText";
import "./style.css";
const Services = () => {
  const { t } = useTranslation();
  console.log(t("blogTitle1"));
  return (
    <SectionWrapper
      BgColor={"#fafafa"}
      title={t(`Services`)}
      id="services-container"
    >
      <div className="container">
        <div className="row justify-content-center">
          {teamTwoData.map((data,index) => (
            // <Link
            //   to={`/ServiceView/${data?.id}`}
            //   className="col-xl-4 col-md-6 col-12 link-service-card"
            //   key={data.id}
            // >
            <div
              className="col-xl-4 col-md-6 col-12 link-service-card"
              key={data.id}
            >
              <div className="service-card text-center">
                <img src={data.img} alt={data.title} />
                {/* <div className="content">
                  <div className="icon-title">
                    <div className="service-title ">
                      <h4 className=" title ms-3  text-center ">
                        {t(`service.${data.title}`)}
                        </h4>
                        </div>
                        </div>
                      </div> */}
                <div className="titleWrapper">
                  <Link to={{pathname: '/services', hash: `#${data.id}` }}>
                    <CustomLinkText text={t(`service.${index}.Title`)} />
                  </Link>
                </div>
              </div>
            </div>
            // </Link>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Services;
