import React from "react";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";


function AdvangeSection(props) {
     const { BgColor, title, subTitle, link, id,img,textcolor, BgunderLine, dataArr} = props;
     const { t } = useTranslation();



  return (
 <>
     
    <SectionWrapper
      BgColor={BgColor}
      id="#"
      title={ title}
      textcolor={textcolor}
      BgunderLine={BgunderLine}
    >
 <div className="container my-5">
 <div className={`row align-items-center justify-content-between mb-4 g-3 `}>
  {
           dataArr.map((data, index) =>
    
            <div className="col-lg-4 col-6 p-lg-3 p-1">
              <div className="shadow bg-white flex-column d-flex align-items-center justify-content-center p-2" style={{borderRadius:"15px"}}>
                <div className="col-12 text-center pl-2"
                ><img src={data.img} alt="Software Delivery on time" title="Delivery on time" class="img-fluid"/>
                </div>
                 <div className="col-12 pl-3 text-center mt-3 mb-1">
                  <h5>{data.desc}</h5>
                  </div>
                  </div>
                  </div>
              
            )
          }</div>

 </div>
     
    </SectionWrapper>
    
    
    </>
  );
};

export default  AdvangeSection;