import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/navigation";
import "swiper/css/free-mode";

import brandCarouselOneData from "./BrandCarouselOneData";
import "./styles.css";
import { Autoplay, FreeMode } from "swiper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const BrandCarouselTwo = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <SectionWrapper
      BgColor={"#fafafa"}
      title={t("brandCursol.title")}
      subTitle={t("brandCursol.desc")}
      id="clients-container"
    >
      <div
        className="slider"
        style={{ direction: i18n.language === "ar" ? "ltr" : "rtl" }}
      >
        <div
          className={`${i18n.language === "ar" ? "right" : "left"
            } slider-track`}
        >
          {brandCarouselOneData.map((data, index) => (
            <div className="slide" key={data.id}>
              <img
                src={require(`../../assets/img/clients-logo/logo/${data.img}`)}
                alt={data.name}
              />
            </div>
          ))}
          {brandCarouselOneData.map((data, index) => (
            <div className="slide" key={data.id}>
              <img
                src={require(`../../assets/img/clients-logo/logo/${data.img}`)}
                alt={data.name}
              />
            </div>
          ))}
        </div>
      </div>
      <Link to="clients">
      <button type="submit" style={{borderRadius:"20px"}} className={`btn btn-primary btn-lg ${lang === "en" ? "ms-5" : "me-5"} mt-3 `} >{t('Clients')}</button>
      </Link>
    </SectionWrapper>
  );
};

export default BrandCarouselTwo;

{
  /* <div className="brand-carousel-wrapper-2 contentt">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-xl-12 col-12 p-0">
              <div className="brand-carousel-active brands" dir="ltr" style={{ direction: "ltr" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Swiper
                  // freeMode={true}
                  ref={swiperRefLocal}
                  slidesOffsetBefore={500}
                  slidesPerView={3}
                  spaceBetween={20}
                  modules={[Autoplay, FreeMode]}
                  className="mySwiper"
                  loop
                  speed={4000}
                  autoplay={{
                    delay: 0,
                    // disableOnInteraction: false,
                    // // pauseOnMouseEnter:
                    // pauseOnMouseEnter: true,
                  }}
                  simulateTouch={false}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    "@0.75": {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                    "@1.00": {
                      slidesPerView: 6,
                      spaceBetween: 40,
                    },
                    "@1.50": {
                      slidesPerView: 8,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {brandCarouselOneData.map((data, index) => (
                    <SwiperSlide
                      className="single-brand-logo justify-content-center"
                      key={data.id}
                    // style={{ padding: "10px" }}
                    >
                      <div className="imgWrapper">
                        <img
                          height={100}
                          // width={180}
                          src={require(`../../assets/img/clients-logo/logo/${data.img}`)}
                          alt={data.name}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div> */
}

// <AliceCarousel
//   duration={400}
//   autoPlay={true}
//   startIndex={1}
//   fadeOutAnimation={true}
//   mouseDragEnabled={true}
//   playButtonEnabled={true}
//   responsive={responsive}
//   autoPlayInterval={0}
//   infinite
//   slideDuration={1000000}
//   autoPlayDirection="rtl"
//   autoPlayActionDisabled={true}
//   // onSlideChange={this.onSlideChange}
//   // onSlideChanged={this.onSlideChanged}
// >
//   <div className="yours-custom-class"><h2>1</h2></div>
//   <div className="yours-custom-class"><h2>2</h2></div>
//   <div className="yours-custom-class"><h2>3</h2></div>
//   <div className="yours-custom-class"><h2>4</h2></div>
//   <div className="yours-custom-class"><h2>5</h2></div>
// </AliceCarousel>
