import { v4 as uuidv4 } from "uuid";
import support from "../../assets/img/blog/support.png";
import demo from "../../assets/img/blog/demo.png";
import security from "../../assets/img/blog/security.png";
import update from "../../assets/img/blog/update.png";
import cash from "../../assets/img/blog/update.png";

const blogOneData = [
  {
    id: uuidv4(),
    title: "featuresTitle1",
    desc: "featuresDesc1",
    authorImg: support,
  },
  {
    id: uuidv4(),
    title: "featuresTitle2",
    desc: "featuresDesc2",
    authorImg: update,
  },
  {
    id: uuidv4(),
    title: "featuresTitle3",
    desc: "featuresDesc3",
    authorImg: demo,
  },
  {
    id: uuidv4(),
    title: "featuresTitle4",
    desc: "featuresDesc4",
    authorImg: cash,
  },
  {
    id: uuidv4(),
    title: "featuresTitle5",
    desc: "featuresDesc5",
    authorImg: security,
  },
];

export default blogOneData;
