import { Link } from "react-router-dom";

const d = new Date();
let currentYear = d.getFullYear();

const FooterOneCopyrights = () => {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-12 text-center">
            <div className="copyright-info">
              <p>
                &copy; {currentYear} Copyright By <Link to="/">Accurate</Link>.
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterOneCopyrights;
