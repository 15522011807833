import cloud from "../../assets/img/stageOfWork/cloud.png";
import develop from "../../assets/img/stageOfWork/data.png";
import document from "../../assets/img/stageOfWork/document.png";
import settings from "../../assets/img/stageOfWork/settings.png";
import visualization from "../../assets/img/stageOfWork/visualization.png";
import webDesign from "../../assets/img/stageOfWork/web-design.png";
export const data = [
  {
    id: 1,
    title: "title7",
    desc: "desc7",
    icon: document,
    color: "#fcb040",
  },
  {
    id: 2,
    title: "title1",
    desc: "desc1",
    icon: document,
    color: "#00a79d",
  },
  {
    id: 3,
    title: "title2",
    desc: "desc2",
    icon: webDesign,
    color: "#aa78c1",
  },
  {
    id: 4,
    title: "title3",
    desc: "desc3",
    icon: develop,
    color: "#ed7251",
  },
  {
    id: 5,
    title: "title4",
    desc: "desc4",
    icon: visualization,
    color: "#face3a",
  },
  {
    id: 6,
    title: "title5",
    desc: "desc5",
    icon: cloud,
    color: "#5398e3",
  },
  {
    id: 7,
    title: "title6",
    desc: "desc6",
    icon: settings,
    color: "#ec5f89",
  },
];
