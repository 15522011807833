import React, { useRef, useState } from "react";
import img1 from "../../assets/img/SS/1-.png";
import img2 from "../../assets/img/SS/2-.png";
import img3 from "../../assets/img/SS/3-.png";
import img4 from "../../assets/img/SS/4-.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import ParagraphText from "../CustmComponent/ParagraphText";
import {FiUsers } from "react-icons/fi";
import {
  RiBankLine,
  RiEBike2Line,
  RiMoneyDollarCircleLine,
  RiRoadMapLine,
} from "react-icons/ri";
import { Pagination, Navigation } from "swiper";
import "./AccurateLogistics.css";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { VscPackage } from "react-icons/vsc";
import { AiOutlineWhatsApp } from "react-icons/ai";
import {
  BiPhoneOutgoing,
  BiBarcodeReader,
  BiTransferAlt,
} from "react-icons/bi";
import { BsChatDots, BsCart3 } from "react-icons/bs";
import CustmButton from "../CustmComponent/Custmbutton";
import AnimationPage from "../CustmComponent/AnimationPage";
export default function AccurateLogistics() {
  const { t } = useTranslation();
  const logisticFeature = [
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img4,
    },
  ];

  const logisticAttribute = [
    {
      icon: FiUsers,
    },
    {
      icon: RiEBike2Line,
    },
    {
      icon: VscPackage,
    },
    {
      icon: RiRoadMapLine,
    },
    {
      icon: AiOutlineWhatsApp,
    },
    {
      icon: IoMdNotificationsOutline,
    },
    {
      icon: BiPhoneOutgoing,
    },
    {
      icon: BiBarcodeReader,
    },
    {
      icon: BsChatDots,
    },
    {
      icon: MdOutlineIntegrationInstructions,
    },
    {
      icon: BsCart3,
    },
    {
      icon: RiMoneyDollarCircleLine,
    },
    {
      icon: BiTransferAlt,
    },
    {
      icon: RiBankLine,
    },
  ];

  const youtubevido = [
    { src: "https://www.youtube.com/embed/iREfuCSgybQ" },
    { src: "https://www.youtube.com/embed/Ll5NxCDPeFk" },
    { src: "https://www.youtube.com/embed/4QzRCuuXRf4" },
    { src: "https://www.youtube.com/embed/VD4MXSwV2zY" },
    { src: "https://www.youtube.com/embed/B3QMDteBEuQ" },
    { src: "https://www.youtube.com/embed/8EZKJIUPtfU" },
    { src: "https://www.youtube.com/embed/v6K8R9mRkKU" },
    { src: "https://www.youtube.com/embed/r7cQqg0tMJk" },
    { src: "https://www.youtube.com/embed/Ka8ZJ5-OcNU" },
  ];

  return (
    <AnimationPage>
      <SectionWrapper
        BgColor={"#fff"}
        title={t("acurrateTitle")}
        fontSize="fs-1"
        subTitle={t("acurrateSubtitle")}
        extraClass={true}
      >
        <div className="container">
          {logisticFeature.map((data, index) => (
            <div
              key={index}
              className={`row align-items-center justify-content-between mb-4 g-3 ${!(
                index % 2
              ) && "flex-row-reverse"}`}
            >
              <div className="col-xl-6 mt-lg-0 col-12">
                <h3>{t(`logisticFeature.${index}.title`)}</h3>
                <ParagraphText text={t(`logisticFeature.${index}.body`)} />
              </div>
              <div className="col-xl-5 col-12 pe-xl-0 text-center">
                <img
                  src={data.img}
                  alt="aboutImg"
                  style={{ maxHeight: "450px" }}
                />
              </div>
            </div>
          ))}
          <div className="justify-content-center my-5">
            <CustmButton btnName={t("Request your trial demo")} />
          </div>
          <div
            className={`row align-items-center justify-content-between g-3}`}
          >
            {logisticAttribute.map((data, index) => (
              <div key={index} className="col-xl-5 mt-lg-0 col-12 mb-4 p-4">
                <data.icon
                  style={{ fontSize: "40px", color: "var(--primaryColor)" }}
                ></data.icon>
                <h3>{t(`logisticAttribute.${index}.title`)}</h3>
                
                <ParagraphText text={t(`logisticAttribute.${index}.body`)} />
              </div>
            ))}
          </div>
        </div>
        <div style={{ direction: "ltr" }}>
          <Swiper
            loop={true}
            slidesPerView={1}
            spaceBetween={10}
            style={{ height: "320px" }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            className="mySwiper videoSlider"
          >
            {youtubevido.map((data, index) => (
              <SwiperSlide>
                {" "}
                <div key={index} style={{ width: "90%", height: "100%" }}>
                  <iframe
                    width="100%"
                    height="250"
                    src={data.src}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    style={{ zIndex: "0" }}
                  ></iframe>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </SectionWrapper>
    </AnimationPage>
  );
}
