// import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { MDBRadio } from 'mdb-react-ui-kit';
import Dropdown from 'react-bootstrap/Dropdown';

import { HiOutlineGlobeAlt } from "react-icons/hi2"
function LangButton({ languagechange }) {
  const [lang, setLang] = useState(localStorage.getItem("language"))
  return (
    <Dropdown >
      <Dropdown.Toggle variant="none" id="dropdown-basic" className="langBtn" style={{color:"#fff"}}>
        <HiOutlineGlobeAlt style={{ fontSize: "25px", color: "#fff" }} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={
          () => {
            languagechange("en")
            setLang("en")
          }}
        >
          <MDBRadio name='flexRadioDefault' id='flexRadioDefault1' label='English' checked={lang === 'en'} />
        </Dropdown.Item>
        <Dropdown.Item onClick={() => {
          languagechange("ar")
          setLang("ar")
        }}
        >
          <MDBRadio name='flexRadioDefault' id='flexRadioDefault2' label='عربي' checked={lang === 'ar'} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown >
  );
}

export default LangButton;
