import React from "react";
import CountUp from "react-countup";
import "./fun.css";
import { useTranslation } from "react-i18next";
import { BiGitBranch } from "react-icons/bi";
import { AiOutlineProject } from "react-icons/ai";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineWorkHistory } from "react-icons/md";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import { useInView } from "react-intersection-observer";
// import '../PageBanner/Header.css'

// const StyledIcon = styled(FaReact)`
//   width: 50px;
// `;
const FunFact = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({ threshold: 0.5 });
  const data = [
    {
      id: 0,
      icon: MdOutlineWorkHistory,
      title: "funFact.title1",
      endCount: 20,
    },
    {
      id: 1,
      icon: IoIosPeople,
      title: "funFact.title2",
      endCount: 10,
    },
    {
      id: 2,
      icon: MdOutlineMiscellaneousServices,
      title: "funFact.title3",
      endCount: 8,
    },
    {
      id: 3,
      icon: AiOutlineProject,
      title: "funFact.title4",
      endCount: 45,
    },
  ];
  return (
    <SectionWrapper BgColor={"#fff"}>
      <section className="fun-fact-wrapper text-center">
        <div className="container">
          <div className="">
            <div className="context">
              <div className="row justify-content-center g-3">
                {/* <div className="col-lg-3 col-md-4 col-12">
                  <div className="single-fun-fact">
                    <MdOutlineWorkHistory
                      style={{ fontSize: "xxx-large" }}
                    ></MdOutlineWorkHistory>

                    <h3> {t("funFact.title1")}</h3>
                    <h5>
                      <CountUp
                        end={t("funFact.end1")}
                        duration={t("funFact.duration1")}
                      ></CountUp>
                      +
                    </h5>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4  col-12">
                  <div className="single-fun-fact">
                    <IoIosPeople style={{ fontSize: "xxx-large" }}></IoIosPeople>
                    <h3>{t("funFact.title2")}</h3>
                    <h5>
                      <CountUp
                        end={t("funFact.end2")}
                        duration={t("funFact.duration2")}
                      ></CountUp>
                      K+
                    </h5>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-12">
                  <div className="single-fun-fact">
                    <MdOutlineMiscellaneousServices
                      style={{ fontSize: "xxx-large" }}
                    ></MdOutlineMiscellaneousServices>
                    <h3>{t("funFact.title3")}</h3>
                    <h5>
                      <CountUp
                        end={t("funFact.end3")}
                        duration={t("funFact.duration3")}
                      ></CountUp>
                      +
                    </h5>
                  </div>
                </div> */}
                {data.map((ele) => (
                  <div className="col-md-3 col-12" key={ele.id}>
                    <div className="single-fun-fact" ref={ref}>
                      <ele.icon style={{ fontSize: "60px" ,color:"var(--primaryColor)"}}></ele.icon>
                      <h2 className="my-2">{t(ele.title)}</h2>
                      <h5 style={{ direction: "ltr" }} className="number py-2 m-0">
                        <span>+</span>
                        {inView ? (
                          <>
                          <CountUp
                            start={0}
                            end={ele.endCount}
                            duration={10}
                          ></CountUp>
                          {ele.id==1?<span>k</span>:""}</>
                        ) : (
                          <span className="fs-5">{ele.endCount}</span>
                        )}
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </SectionWrapper>
  );
};

export default FunFact;
