import React from "react";
// import img1 from "../../assets/img/why-bg.jpg";
import { data } from "./WhyUsData";
// import { useParams } from "react-router-dom";
// import { color } from "framer-motion";
import "./WhyUs.css";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import cardBg from "../../assets/img/17580.jpg";

import { style } from "typestyle";
import { useState } from "react";

const WhyUs = () => {
  const niceColors = style({
    $nest: {
      "&:hover": {
        color: "white",
      },
    },
  });

  const { t } = useTranslation();
  return (
    <SectionWrapper
      BgColor={"#fff"}
      title={t(`whyUs.h2`)}
      subTitle={t(`whyUs.p`)}
    >
      <div className="container">
        <div className="row justify-content-center g-lg-5 g-3 stagesWrapper">
          {data.map((blog) => (
            <div className={`col-lg-3 stageCard ${niceColors}`} key={blog.id}>
              <div className="card-bgWrapper">
                <img src={cardBg} alt="bg" height={"100%"} width={"100%"} />
              </div>
              <div
                className="iconWrapper"
                style={{
                  backgroundImage: `url(${blog.icon})`,
                  border: `12px solid ${blog.color}`,
                }}
              ></div>
              <div
                className={`contentBg `}
                style={{ border: `2px solid ${blog.color}` }}
              >
                <span class="bg" style={{ background: `${blog.color}` }}></span>
                <div className="title fs-4">{t(`whyUs.${blog.title}`)}</div>
                <div className="desc mt-1 px-3 fs-6">
                  {t(`whyUs.${blog.desc}`)}
                </div>
              </div>
              {/* <div className="box-features height-370 blog contentt">
                <div className="ppp">
                  <p className="number">{blog.id}</p>
                </div>
                <h4 className="box-title pt-4"> {t(`whyUs.${blog.title}`)}</h4>
                <p className="box-info">{t(`whyUs.${blog.desc}`)}</p>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default WhyUs;
