import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import PageBanner from "../Components/PageBanner";
import banner from "../assets/img/banner-img.png";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import { useTranslation } from "react-i18next";
import AnimationPage from "../Components/CustmComponent/AnimationPage";

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <AnimationPage>
      <PageBanner
        title="Contact Us"
        bannerBg={banner}
        currentPage={t("Contact")}
      />
      <ContactPageContents />
    </AnimationPage>
  );
};

export default ContactPage;
