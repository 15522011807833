import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { useTranslation } from "react-i18next";
import ParagraphText from "./ParagraphText";
function PannerCompant(props) {

  const { BgImage, SideImage, title, subTitle, link, id,desc,desc2 } = props;

  return (
    <div
      className="SectionWrapper py-2 "
      style={{ backgroundImage: `url(${BgImage})` }}
      id={id}
    >
      <section>
        <div className="container py-5">
          <div className="row align-items-center ">
            <div
              className="col-xl-6 mt-lg-0 col-12 "
              style={{ color: "white" }}
            >
              <div className="block-contents">
                <div className="section-title">
                  <h2 className="mb-4 section-title" style={{ color: "white" }}>
                    {title}
                  </h2>
                </div>
                <ParagraphText text={subTitle} />
                <ParagraphText text={desc} />
                <ParagraphText text={desc2} />
              </div>
            </div>
            <div className="col-xl-6 col-12 pe-xl-0 text-center disapper">
            <img src={SideImage} alt="aboutImg" className="imgHeight" />
            </div>
          </div>
        </div>
      </section>

      {/* {(title || subTitle) && (
        <div className="d-flex justify-content-center flex-column align-items-center my-5">
          {title && link ? (
            <Link to={`${link}`}>{title}</Link>
          ) : (
            <div className={`title text-primary mb-2 text-capitalize ${fontSize ? fontSize : "fs-2"}`}>
              {title}
            </div>
          )}
          {subTitle && (
            <p className={`subTtitle p-0 px-5 text-center fs-5 ${extraClass?"logisticSubtitle":""}`}>{subTitle}</p>
          )}
        </div>
      )}
      <div className="py-5">{props.children}</div> */}
    </div>
  );
}

PannerCompant.propTypes = {
  BgColor: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fontSize: PropTypes.string,
};

export default PannerCompant;