import React from "react";
import AnimationPage from "../CustmComponent/AnimationPage";
import { style } from "typestyle";
import Error404 from "../../assets/img/error404.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function NotFound() {
  const { t } = useTranslation();

  const notFoundWrapper = style({
    height: "calc(100vh - 100px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  });
  return (
    <AnimationPage>
      <div className={notFoundWrapper}>
        <img src={Error404} alt="error404Alt" height={150} />
        <p className="fs-2 my-4">{t("pageNotFound")}</p>
        <Link to="/" className="btn btn-outline-primary">
          {t("Home")}
        </Link>
      </div>
    </AnimationPage>
  );
}
