import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import PageBanner from "../Components/PageBanner";
import banner from "../assets/img/banner-img.png";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import { useTranslation } from "react-i18next";
import AnimationPage from "../Components/CustmComponent/AnimationPage";
import Jobs from "../Components/Jobs/Jobs";

const JobsPage = () => {
  const { t } = useTranslation();
  return (
    <AnimationPage>
      <PageBanner
        title="jobs"
        bannerBg={banner}
        currentPage={t("jobs")}
      />
      <Jobs />
    </AnimationPage>
  );
};

export default JobsPage;
