import React from "react";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import ParagraphText from "../CustmComponent/ParagraphText";

function AboutUsSection(props) {
 const { BgColor, title, subTitle, link, id,img } = props;
  const { t } = useTranslation();
  return (
 <>

    
    
    <SectionWrapper
      BgColor={BgColor}
      id=""
    >
      <div className="container my-5">
   <div  className={`row align-items-center mb-4 g-3 }`}>
    <div className="col-xl-4 col-12 pe-xl-0 text-center" style={{border:"12px solid #f2f6ff",borderRadius:"8px"}}>
        <img src={img} alt="aboutImg" />
      </div>
      <div className="col-xl-8 mt-lg-0 col-12">
        <h3>{title}</h3>
        <ParagraphText  text={subTitle} />
      </div>
      
    </div></div>
     
    </SectionWrapper>
   
    
    </>
  );
};

export default AboutUsSection;