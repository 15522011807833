import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../../assets/img/About me.gif";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import ParagraphText from "../CustmComponent/ParagraphText";

const AboutTwo = () => {
  const { t } = useTranslation();
  return (
    <SectionWrapper
      BgColor={"#fff"}
      title={t("about2.h2")}
      subTitle={t("about2.title")}
      id="about-container"
    >
      <section className="about-section">
        <div className="container">
          <div className="row align-items-center g-3">
            <div className="col-xl-7 mt-lg-0 col-12">
              <ParagraphText text={t("about2.desc")} />
              {/* <Link to="/about" className="my-3 d-flex">
                {t("about2.readMore")}
              </Link> */}
              {/* todo : make read more */}
            </div>
            <div className="col-xl-5 col-12 pe-xl-0 text-center">
              <img src={aboutImg} alt="aboutImg" style={{maxHeight:"450px"}}/>
            </div>
          </div>
        </div>
      </section>
    </SectionWrapper>
  );
};

export default AboutTwo;
