import ERP from "../../assets/img/png/ERPSystem.png";
import kk from "../../assets/img/png/Logistics.png";
import school from "../../assets/img/png/School Management System.png";
import ECommerce from "../../assets/img/png/E Commerce.png";
import Restaurant from "../../assets/img/png/Restaurant Management System.png";
import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./style.css";
import "@lottiefiles/lottie-player";
import { Autoplay } from "swiper";
import { BiLeftArrowAlt } from "react-icons/bi";
import ParagraphText from "../CustmComponent/ParagraphText";

export default function HeroTwo() {
  let sliderArr = [
 
    {
      id: "logistic",
      url: kk,
      title: "mainSlider.prologistics.title",
      description: "mainSlider.prologistics.des",
      pathname: "/projectDetails/Logistics",
    },
    {
      id: "ERP",
      url: ERP,
      title: "mainSlider.proErp.title",
      description: "mainSlider.proErp.des",
      pathname: `/projectDetails/AccurateErp`,
    },
 
    {
      id: "Ecommerce",
      url: ECommerce,
      title: "mainSlider.proCommerce.title",
      description: "mainSlider.proCommerce.des",
      pathname: `/projectDetails/AccUratepos`,
    },
    // {
    //   id: "school",
    //   url: school,
    //   title: "mainSlider.proSchool.title",
    //   description: "mainSlider.proSchool.des",
    //   pathname: `/projectDetails/privateschools`,
    // },
    // {
    //   id: "restaurant",
    //   url: Restaurant,
    //   title: "mainSlider.proRestaurant.title",
    //   description: "mainSlider.proRestaurant.des",
    //   pathname: `/projectDetails/AccurateRestaurants`,
    // },
  ];
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = "";
  };
  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev(); // slide to the previous slide
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext(); // slide to the next slide
  };

  const swiperRef = useRef(null); // ref to the Swiper component
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  return (
    <div
      className={`homeSlider ${lang === "ar" && "flip"} py-5`}
      style={{ direction: "ltr" }}
    >
      <div className="mx-3">
        <Swiper
          ref={swiperRef}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 9000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="w-full pt-12 pb-10 mySwiper"
        >
          {sliderArr.map((ele) => (
            <SwiperSlide key={ele.id}>
              <div
                className={`row align-items-center justify-content-center slideWrapper ${lang ===
                  "ar" && "flex-row-reverse"}`}
              >
                <div
                  className="col-lg-6 col-12 padding"
                  style={{ background: "transparent" }}
                >
                  <div
                    className="w-100"
                    style={{ textAlign: lang === "en" ? "start" : "end",color:"white" }}
                  >
                    <h2 className="mb-4" style={{color:"white"}}>{t(ele.title)}</h2>
                    <ParagraphText text={t(ele.description)} />
                    {/* {ele.id === "Ecommerce" ? (
                      ""
                    ) : (
                      <Link
                        to={{ pathname: `${ele.pathname}` }}
                        className=" mt-3"
                        style={{ display: "block" }}
                      >
                        {t("mainSlider.btnreadmore")}
                      </Link>
                    )} */}
                    {/* todo : uncomment read more */}
                  </div>
                </div>
                <div className="col-lg-6 col-12 hidden">
                  <img alt={ele.url} src={ele.url} height={100} />
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="hidden md:block">
            <button
              className="autoplay-progress autoplay-progress-next"
              slot="container-end"
              onClick={handleNextClick}
            >
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <svg viewBox="0 0 48 48">
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <BiLeftArrowAlt
                style={{
                  fontSize: "25px",
                  transform: "rotate(180deg)",
                }}
              />
              <span ref={progressContent}></span>
            </button>
            <div className="autoplay-progress autoplay-progress-prev">
              <button onClick={handlePrevClick}>
                <svg viewBox="0 0 48 48">
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <BiLeftArrowAlt
                  style={{
                    fontSize: "25px",
                    position: "absolute",
                    left: "50%",
                    top: "1px",
                    transform: "translateX(-14%)",
                  }}
                />
              </button>
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}
