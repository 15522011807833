import React from "react";
const PageBanner = ({ bannerBg, currentPage, title }) => {
  return (
    <div
      className="page-banner-wrap" style={{background:"#fafafa"}}
    >
      <img src={bannerBg} alt="banner" />
    </div>
  );
};

export default PageBanner;
