import React from "react";
import blogOneData from "./BlogOneData";
import "./BlogTow.css";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import ParagraphText from "../CustmComponent/ParagraphText";
// import { Link } from 'react-router-dom';

const BlogTwo = () => {
  const { t } = useTranslation();
  return (
    <SectionWrapper
      BgColor={"#fff"}
      title={t(`blog.title`)}
      subTitle={t(`blog.desc`)}
    > 
      <div className="container blog-container">
        <div className="row justify-content-center gap-1">
          {blogOneData.map((blog) => (
            <div className="col-lg-3 " key={blog.id}>
              <div className="blog content pt-5 px-4">
                <img
                  src={blog.authorImg}
                  width={50}
                  alt="TechIcon"
                />
                <h4 className="box-title mb-3">{t(`blog.${blog.title}`)}</h4>
                <ParagraphText text={t(`blog.${blog.desc}`)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default BlogTwo;
