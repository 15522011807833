const brandCarouselOneData = [
  {
    id: 0,
    name: "Egy Express",
    img: "egyexpress.png",
  },
  {
    id: 1,
    name: "Telegraph Express",
    img: "telegraph.png",
  },
  {
    id: 2,
    name: "Yalla Express",
    img: "yalla.png",
  },
  {
    id: 3,
    name: "Any time Express",
    img: "anytime.png",
  },
  {
    id: 4,
    name: "Sky Express",
    img: "skyexpress.png",
  },
  {
    id: 5,
    name: "Merage Express",
    img: "Merage.png",
  },
  {
    id: 6,
    name: "Nova Express",
    img: "nova.png",
  },
  {
    id: 7,
    name: "Flash Express",
    img: "flash.png",
  },
  {
    id: 8,
    name: "Bebo Fast Express",
    img: "bebofast.png",
  },
  {
    id: 9,
    name: "Turbo Express",
    img: "turbo.png",
  },
  {
    id: 10,
    name: "Magic Express",
    img: "magic.png",
  },
  {
    id: 11,
    name: "3Sare3 Express",
    img: "3sare3.png",
  },
  {
    id: 12,
    name: "Alnours Express",
    img: "alnours.png",
  },
  {
    id: 13,
    name: "Rocket Express",
    img: "rocket.png",
  },
  {
    id: 14,
    name: "Amana Express",
    img: "amana.png",
  },
  {
    id: 15,
    name: "Tayer Express",
    img: "tayer.png",
  },
  {
    id: 16,
    name: "Door To Door ",
    img: "DoortoDoor.png",
  },
  {
    id: 17,
    name: "IB Courier Express",
    img: "IBCourier.png",
  },
  {
    id: 18,
    name: "Giriip Express",
    img: "giriip.png",
  },
  {
    id: 19,
    name: "Faster Ex",
    img: "fasterex.png",
  },
  {
    id: 20,
    name: "Bosla Express",
    img: "bosla.png",
  },
  {
    id: 21,
    name: "Azzam Express",
    img: "Azzam.png",
  },
  {
    id: 22,
    name: "Thiqa Express",
    img: "thiqa.png",
  },
  {
    id: 23,
    name: "Ibs Line Express",
    img: "IBS.png",
  },
  {
    id: 24,
    name: "Juka Express",
    img: "juka.png",
  },
  {
    id: 25,
    name: "For You Express",
    img: "foru.png",
  },
  {
    id: 26,
    name: "DAYER Express",
    img: "DAYER.png",
  },
  {
    id: 27,
    name: "Jaso Express",
    img: "jaso.png",
  },
  {
    id: 28,
    name: "Sabaa Express",
    img: "sabaa.png",
  },
  {
    id: 29,
    name: "Car Go Express",
    img: "cargo.png",
  },
  {
    id: 30,
    name: "MA Express",
    img: "ma.png",
  },
  {
    id: 31,
    name: "Infolx",
    img: "infolx.png",
  },
  {
    id: 32,
    name: "Nexxt Express",
    img: "nexxt.png",
  },
  {
    id: 33,
    name: "Next Express",
    img: "next.png",
  },
  {
    id: 34,
    name: "Mars Express",
    img: "mars.png",
  },
  {
    id: 35,
    name: "MAS SERV Express",
    img: "MASSERV.png",
  },
  {
    id: 36,
    name: "Massa Express",
    img: "Massa.png",
  },
  {
    id: 37,
    name: "On Time Ex",
    img: "ontimeex.png",
  },
  {
    id: 38,
    name: "On Time",
    img: "ontime.png",
  },
  {
    id: 39,
    name: "Q Express",
    img: "Qexpress.png",
  },
  {
    id: 40,
    name: "Easil Express",
    img: "wasil.png",
  },
  {
    id: 41,
    name: "Zedex",
    img: "zedex.png",
  },
  {
    id: 42,
    name: "Tracking Express",
    img: "tracking.png",
  },
  {
    id: 43,
    name: "Thunder Express",
    img: "Thunder.png",
  },
  {
    id: 44,
    name: "A To Z",
    img: "AToZ.png",
  },
  {
    id: 45,
    name: "Move Express",
    img: "Moveexpress.png",
  },
  {
    id: 46,
    name: "Yedaiah",
    img: "yedaiah.png",
  },
  {
    id: 47,
    name: "Bullet Express",
    img: "bulletexpress.png",
  },
  {
    id: 48,
    name: "Bullet",
    img: "bullet.png",
  },
  {
    id: 49,
    name: "Bareedy Express",
    img: "bareedy.png",
  },
  {
    id: 50,
    name: "Eb3at Express",
    img: "eb3at.png",
  },
  {
    id: 51,
    name: "EG",
    img: "EG.png",
  },
  {
    id: 52,
    name: "Upex",
    img: "upex.png",
  },
  {
    id: 53,
    name: "Roadex",
    img: "Roadex.png",
  },
  {
    id: 54,
    name: "Sahlah Express",
    img: "sahlah.png",
  },
  {
    id: 55,
    name: "Target",
    img: "target.png",
  },
  {
    id: 56,
    name: "Onix",
    img: "onix.png",
  },
  {
    id: 57,
    name: "Dream Express",
    img: "Dream.png",
  },
  {
    id: 58,
    name: "Pulse Group",
    img: "PulseGroup.png",
  },
  {
    id: 59,
    name: "ELRESALA",
    img: "ELRESALA.png",
  },
  {
    id: 60,
    name: "Eltayar Express",
    img: "eltayar.png",
  },
  {
    id: 61,
    name: "Yes Express",
    img: "Yesexpress.png",
  },
  {
    id: 62,
    name: "Bike Express",
    img: "bike.png",
  },
  {
    id: 63,
    name: "Tak Express",
    img: "Tak.png",
  },
  {
    id: 64,
    name: "Elitex",
    img: "elitex.png",
  },
  {
    id: 65,
    name: "Enveo",
    img: "enveo.png",
  },
  {
    id: 66,
    name: "Magex",
    img: "magex.png",
  },
  {
    id: 67,
    name: "Team For Shipping",
    img: "Teamforshipping.png",
  },
  {
    id: 68,
    name: "Private Group",
    img: "privategroup.png",
  },
  {
    id: 69,
    name: "A M Y",
    img: "amy.png",
  },
  {
    id: 70,
    name: "Care Express",
    img: "care.png",
  },
  {
    id: 71,
    name: "MJK Express",
    img: "MJK.png",
  },
  {
    id: 72,
    name: "TRAS",
    img: "TRAS.png",
  },
  {
    id: 73,
    name: "Troy Express",
    img: "Troy.png",
  },
  {
    id: 74,
    name: "Vezba",
    img: "Vezba.png",
  },
  {
    id: 75,
    name: "Vip Express",
    img: "vip.png",
  },
  {
    id: 76,
    name: "Tawsseel Express",
    img: "Tawsseel.png",
  },
  {
    id: 77,
    name: "ADM",
    img: "ADM.png",
  },
  {
    id: 78,
    name: "3M Express",
    img: "3Mexpress.png",
  },
  {
    id: 79,
    name: "Shiply Express",
    img: "Shiply.png",
  },
  {
    id: 80,
    name: "Wolf Express",
    img: "wolf.png",
  },
  {
    id: 81,
    name: "World Shipping",
    img: "worldshipping.png",
  },
  {
    id: 82,
    name: "X Flex",
    img: "XFlex.png",
  },
  {
    id: 83,
    name: "Zwawe",
    img: "Zwawe.png",
  },
  {
    id: 84,
    name: "Agead Express",
    img: "agead.png",
  },
  {
    id: 85,
    name: "Wafernalkom",
    img: "wafernalkom.png",
  },
  {
    id: 86,
    name: "Dash Express",
    img: "Dash.png",
  },
  {
    id: 87,
    name: "Deal",
    img: "deal.png",
  },
  {
    id: 88,
    name: "Mared",
    img: "Mared.png",
  },
  {
    id: 89,
    name: "JAM",
    img: "JAM.png",
  },
  {
    id: 90,
    name: "Itex",
    img: "itex.png",
  },
  {
    id: 91,
    name: "Sphinx",
    img: "sphinx.png",
  },
  {
    id: 92,
    name: "STS",
    img: "STS.png",
  },
  {
    id: 93,
    name: "Shipper Express",
    img: "shipper.png",
  },
  {
    id: 94,
    name: "Pluto",
    img: "pluto.png",
  },
  {
    id: 95,
    name: "Eagels",
    img: "Eagels.png",
  },
  {
    id: 96,
    name: "FASTER",
    img: "FASTER.png",
  },
  {
    id: 97,
    name: "Nibras",
    img: "nibras.png",
  },
  {
    id: 98,
    name: "Mustang",
    img: "Mustang.png",
  },
  {
    id: 99,
    name: "Molvoo Express",
    img: "Molvoo.png",
  },
  {
    id: 100,
    name: "MGM",
    img: "mgm.png",
  },
  {
    id: 101,
    name: "Horizon",
    img: "Horizon.png",
  },
  {
    id: 102,
    name: "Guarantee",
    img: "Guarantee.png",
  },
  {
    id: 103,
    name: "Gallop express",
    img: "Gallopexpress.png",
  },
  {
    id: 104,
    name: "Fly Express",
    img: "fly.png",
  },
  {
    id: 105,
    name: "Ernest Express",
    img: "Ernestexpress.png",
  },
  {
    id: 106,
    name: "Spedoo We",
    img: "Spedoo.png",
  },
  {
    id: 107,
    name: "Sky Express",
    img: "sky.png",
  },
];

export default brandCarouselOneData;
