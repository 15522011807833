import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/193.png";
import img2 from "../../assets/img/Broken light-bulb-pana.svg";
import img3 from "../../assets/img/28663.png";
import img4 from "../../assets/img/International-cooperation-bro.svg";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import "./ProjectsTab.css";
import ParagraphText from "../CustmComponent/ParagraphText";

export const ourInfo = [
  {
    id: "Approach",
    name: "projectName1",
    title: "projectTitle1",
    desc: "projectDesc1",
    littleDesc: "projectLittleDesc1",
    img: img1,
  },
  {
    id: "POWERFUL",
    name: "projectName2",
    title: "projectTitle2",
    desc: "projectDesc2",
    littleDesc: "projectLittleDesc2",
    img: img2,
  },
  {
    id: "GLOBAL",
    name: "projectName3",
    title: "projectTitle3",
    desc: "projectDesc3",
    littleDesc: "projectLittleDesc3",
    img: img3,
  },
  {
    id: "STRONG",
    name: "projectName4",
    title: "projectTitle4",
    desc: "projectDesc4",
    littleDesc: "projectLittleDesc4",
    img: img4,
  },
];
function ProjectsTab() {
  const { t } = useTranslation();
  return (
    <SectionWrapper BgColor={"#fafafa"}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <ul
              className="d-flex justify-content-center mb-5"
              id="pills-tab"
              role="tablist"
            >
              {ourInfo.map((ele, index) => (
                <li className="m-3" role="presentation" key={ele.id}>
                  <button
                    className={`projectTab fs-5 ${index === 0 ? "active" : ""}`}
                    id={`${ele.id}-${index}`}
                    data-bs-toggle="pill"
                    data-bs-target={`#${ele.id}`}
                    type="button"
                    role="tab"
                    aria-controls={ele.id}
                    aria-selected={index === 0 ? "true" : "false"}
                  >
                    {/* <i className="flaticon-notebook"></i> */}
                    {t(`projects.${ele.name}`)}
                  </button>
                </li>
              ))}
            </ul>
            {ourInfo.map((ele, index) => (
              <div className="tab-content" id="pills-tabContent" key={ele.id}>
                <div
                  className={`tab-pane fade ${
                    index === 0 ? "show active" : ""
                  }`}
                  id={ele.id}
                  role="tabpanel"
                  aria-labelledby={ele.id}
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h3> {t(`projects.${ele.title}`)}</h3>
                        <ParagraphText text={t(`projects.${ele.littleDesc}`)} />
                        <div className="data-flex"></div>
                        {/* <Link to={{ pathname: `/projectDetail/${ele.id}` }}>
                            {t("about2.readMore")}
                            <i className="fas fa-long-arrow-right"></i>
                          </Link> */}

                        {/* <Link
                          to={{ pathname: `/projectDetail/${ele.id}` }}
                          className="mt-3"
                          style={{ color: "var(--secondColor)" }}
                        >
                          {t("about2.readMore")}
                        </Link> */}
                      </div>
                      <div className="col-lg-6 col-12 ">
                        <div className="d-flex justify-content-center">
                          <div className="tab-img ">
                            <img
                              src={ele.img}
                              alt=""
                              width={400}
                              height={280}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
}

export default ProjectsTab;
