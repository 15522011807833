import React from 'react'
import SectionWrapper from '../Components/CustmComponent/SectionWrapper'
import AnimationPage from '../Components/CustmComponent/AnimationPage'
import PageBanner from '../Components/PageBanner'
import banner from "../assets/img/banner-img.png";
import { useTranslation } from 'react-i18next';
import PaginatedClients from '../Components/Clients/Clients';

export const ClintsPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <AnimationPage>
      <PageBanner
        title="jobs"
        bannerBg={banner}
        currentPage={t("jobs")}
      />
      <SectionWrapper
        BgColor={"#fff"}
        title={t("Clients")}
        // subTitle={t("our clients")}
      >
        <PaginatedClients clientsPerPage={12} />
      </SectionWrapper>
    </AnimationPage>
  )
}
