import React from 'react'
import { data } from "./ClientsData"
import { useState } from 'react';
import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import "./Client.css"
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';



function ClientsView({ clients }) {

  return (
    <div className='container'>
      <div className='row d-flex justify-content-center'>
        {clients && clients.map((client) => <div className='col-md-3 col-xs-4 text-center d-flex align-items-stretch'
        >
          <div className='m-2 p-4 d-flex align-items-center justify-content-center ' style={{
            backgroundColor: "#fafafa", borderRadius: "15px", boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)"
          }}>
            <img src={require(`../../assets/img/clients-logo/logo/${client.img}`)} alt={client.name} className='w-50 clientImg' />
            {/* <p>{client.name}</p> */}
          </div>
        </div>)}
      </div>
    </div>
  );
}

export default function PaginatedClients({ clientsPerPage }) {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const endOffset = itemOffset + clientsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / clientsPerPage));
  }, [itemOffset, clientsPerPage]);

 
  const handlePageClick = (event) => {
    const newOffset = event.selected * clientsPerPage % data.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  return (
    <>
      <ClientsView clients={currentItems} />
      <div className='paginate'>
      <ReactPaginate
        nextLabel={<IoIosArrowForward/>}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<IoIosArrowBack/>}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </div>
    </>
  );
}
