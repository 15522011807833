import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
const CustmButton = (props) => {
  const { btnName } = props
  return (
    <Link
      to={{ pathname: `/contact` }}
      className="btn-primary btn-lg rounded-pill"
    >
      <div className="titleWrapper">
        <p className="title p-2"> {btnName}{(document.body.dir === "ltr") ? <IoIosArrowForward /> : <IoIosArrowBack />} </p>
      </div>
    </Link>
  )
};

export default CustmButton;