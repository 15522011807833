import AboutTwo from "../Components/About/AboutTwo";
import ServicesTwo from "../Components/Services/ServicesTwo";
import CtaOne from "../Components/Cta/CtaOne";
import BrandCarouselTwo from "../Components/Brands/BrandCarouselTwo";
import ProjectsTab from "../Components/Projects/ProjectsTab";
import FooterTwo from "../Components/Footer/FooterTwo";
import BlogTwo from "../Components/Blog/BlogTwo";
import Services from "../Components/ServiceSections/Services";
import WhyUs from "../Components/About/WhyUs";
import FunFact from "../Components/FunFact/FunFact";
// import PortfolioTwo from "../Components/Portfolio/PortfolioTwo";
// import Header from "../Components/Header/Header";
import HeroTwo from "../Components/Hero/HeroTwo";
import ProjectPage1 from "./Projectpagone";

// import ReactWOW from "react-wow"
import "../Components/FunFact/fun.css";
import { useEffect } from "react";
import AnimationPage from "../Components/CustmComponent/AnimationPage";
const HomeTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  return (
    <>
      {/* <AnimationPage> */}
        <HeroTwo />
        <AboutTwo />
        <ServicesTwo />
        <FunFact />
        <BrandCarouselTwo />
        <BlogTwo />
        <ProjectsTab />
        <WhyUs />
        <Services />
        <ProjectPage1 />
      {/* </AnimationPage> */}
    </>
  );
};

export default HomeTwo;
