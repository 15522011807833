import React from "react";
import { Link } from "react-router-dom";
// import bannerBg from "../assets/img/home2/hero1.png";
import pos from "../assets/img/crop/E-Commerce.png"
import img1 from "../assets/img/crop/Logistics.png";
import img2 from "../assets/img/crop/ERP.png";
import "./styleproject.css";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../Components/CustmComponent/SectionWrapper";
import CustomLinkText from "../Components/CustmComponent/CustomLinkText"

export const projectArr = [
  {
    id: "accurate-logistics",
    to:"ok",
    name: "mainSlider.prologistics.title",
    title: "",
    desc: "projectlogistic.projdes",
    img: img1,
    src: "https://www.youtube.com/embed/iREfuCSgybQ",
  },
  {
    id: "Accurate-Erp",
    to:"ok",
    name: "mainSlider.proErp.title",
    title: "",
    desc: "projectErp.projdes",
    img: img2,
    src: "https://www.youtube.com/embed/stc-FlYV6TA",
 
  },
  // {
  //   id: "privateschools",
  //   name: "projectschool.projname",
  //   title: "",
  //   desc: "projectschool.projdes",
  //   projdesc: [
  //     {
  //       subtitle: "projectschool.restlist.titl1",
  //       desc: "projectschool.restlist.des1",
  //     },
  //     {
  //       subtitle: "projectschool.restlist.titl2",
  //       desc: "projectschool.restlist.des2",
  //     },
  //     {
  //       subtitle: "projectschool.restlist.titl3",
  //       desc: "projectschool.restlist.des3",
  //     },
  //     {
  //       subtitle: "projectschool.restlist.titl4",
  //       desc: "projectschool.restlist.des4",
  //     },
  //     {
  //       subtitle: "projectschool.restlist.titl5",
  //       desc: "projectschool.restlist.des5",
  //     },
  //     {
  //       subtitle: "projectschool.restlist.titl6",
  //       desc: "projectschool.restlist.des6",
  //     },
  //     {
  //       subtitle: "projectschool.restlist.titl7",
  //       desc: "projectschool.restlist.des7",
  //     },

  //     {
  //       subtitle: "projectschool.restlist.titl8",
  //       desc: "projectschool.restlist.des8",
  //     },
  //   ],
  //   img: school,
  //   src: "https://www.youtube.com/embed/stc-FlYV6TA",
  //   down: download4,
  //   down1: downl4,
  // },

  {
    id: "AccUratepos",
    name: "mainSlider.proCommerce.title",
    title: "",
    desc: "projectPos.projdes",
    img: pos,
    src: "https://www.youtube.com/embed/stc-FlYV6TA",
  },
  // {
  //   id: "AccurateRestaurants",
  //   name: "projectRest.projname",
  //   title: "",
  //   desc: "projectRest.projdes",
  //   projdesc: [
  //     {
  //       subtitle: "projectRest.restlist.titl1",
  //       desc: "projectRest.restlist.des1",
  //     },
  //     {
  //       subtitle: "projectRest.restlist.titl2",
  //       desc: "projectRest.restlist.des2",
  //     },
  //     {
  //       subtitle: "projectRest.restlist.titl3",
  //       desc: "projectRest.restlist.des3",
  //     },
  //     {
  //       subtitle: "projectRest.restlist.titl4",
  //       desc: "projectRest.restlist.des4",
  //     },
  //     {
  //       subtitle: "projectRest.restlist.titl5",
  //       desc: "projectRest.restlist.des5",
  //     },
  //   ],
  //   img: Restaurant,
  //   src: "https://www.youtube.com/embed/stc-FlYV6TA",
  //   down: download3,
  //   down1: downl3,
  // },
];
function ProjectPage1() {
  const { t } = useTranslation();
  return (
    <SectionWrapper
      BgColor={"#fff"}
      title={t(`OurSolutions`)}
      id="projects-container"
    >
      <div className="container">
        <div className="row justify-content-center">
          {projectArr.map((data) =>
            data.to ? (
              <Link
                to={{ pathname: `/apps/${data.id}` }}
                className="col-xl-4 col-md-6 col-12 link-projects-card"
                key={data.id}
              >
                <div className="projects-card"  >
                  <img src={data.img} alt={data.title} />
                  <div className="titleWrapper">
                    <p className="title">{t(data.name)}</p>
                  </div>
                </div>
              </Link>
            ) : (
              <div
                className="col-xl-4 col-md-6 col-12 link-projects-card"
                key={data.id}
              >
                <div className="projects-card" >
                  <img src={data.img} alt={data.title} />
                  <div className="titleWrapper">
                    {/* <p className="title">{t(data.name)}</p> */}
                    <CustomLinkText text={t(data.name)}/>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </SectionWrapper>
  );
}

export default ProjectPage1;

{
  /* <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="">
              
              <div className="row">
                {projectArr.map((ele, index) => (
                  <Link
                    to={{ pathname: `/projectDetails/${ele.id}` }}
                    className="col-xl-4 col-md-6 col-12"
                    key={ele.id}
                  >
                    <div className="single-service-card ghgh">
                      <div
                        className="service-cover-img bg-cover img"
                        style={{ backgroundImage: `url(${ele.img})` }}
                      ></div>
                      <div className="middle">
                        <div className="text">
                          <i className="fa fa-link" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div className="content">
                        <div className="icon-title">
                          <div className="service-title">
                            <h4 className="m-0 title"> {t(ele.name)}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */
}
