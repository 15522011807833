import React from "react";
import PannerCompant from "../CustmComponent/PannerCompant";
import { useTranslation } from "react-i18next";
import panner from "../../assets/img/panner1.svg";
import pannerImage from "../../assets/img/services/Devices-cuate2.png";
// import CustmButton from "../CustmComponent/Custmbutton";
import ParagraphText from "../CustmComponent/ParagraphText";
import website from "../../assets/img/Website Creator.gif";
import mobile from "../../assets/img/services/mobile.png";
import software from "../../assets/img/Open source.gif";
import img1 from "../../assets/img/services/1.png";
import img2 from "../../assets/img/services/2.png";
import img3 from "../../assets/img/services/3.png";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import { useEffect } from "react";

export const ServicePage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const hash = window?.location?.hash;

    if (hash) {
      const element = document.querySelector(hash);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  const servicesFeature = [
    {
      img: website,
      imgNumber: img1,
      id: "website",
    },
    {
      img: mobile,
      imgNumber: img2,
      id: "mobile",
      color: "#fafafa"
    },
    {
      img: software,
      imgNumber: img3,
      id: "software",
    },
  ];

  return (
    <div>
      <PannerCompant
        BgImage={panner}
        SideImage={pannerImage}
        title={t("Servicestitle")}
        subTitle={t("servicesPannerTitle")}
        desc={t("servicesPannerDesc")}
        desc2={t("servicesPannerDesc2")}
        id="service"
      />
      <SectionWrapper
        BgColor={"#fff"}

        title={t("Services")}
        fontSize="fs-1"
        // subTitle={t("ServicesSubtitle")}
        extraClass={true}
      >

        {servicesFeature.map((data, index) => (
          <div
            key={index}
            className="align-items-center justify-content-center"
            style={{ background: `${data?.color}` }} 
          >
            <div className={`container row align-items-center justify-content-center mb-4 g-3 ${!(
              index % 2
            ) && "flex-row-reverse"}`} id={data.id} >
              <div className="col-xl-6 mt-lg-0 col-12">
                <img src={data.imgNumber} alt="servicessectionImg" />
                <h3>{t(`service.${index}.Title`)}</h3>
                <ParagraphText text={t(`service.${index}.Desc`)} />
              </div>
              <div className="col-xl-5 col-12 pe-xl-0 text-center" >
                <img
                  src={data.img}
                  alt="servicesImg"
                  style={{ maxHeight: "450px" }}
                />
              </div>
            </div>
          </div>
        ))}
      </SectionWrapper>
    </div>
  );
};
