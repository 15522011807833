import img from "../../assets/img/clients-logo/logo/egyexpress.png"

export const data = [{
  id: 13,
  name: "rocket express",
  img: "rocket.png",
}, {
  id: 0,
  name: "egy express",
  img: "egyexpress.png",
},
{
  id: 132,
  name: "asphaalt",
  img: "asphalt.png",
},
{
  id: 1,
  name: "telegraph express",
  img: "telegraph.png",
},
{
  id: 2,
  name: "yalla express",
  img: "yalla.png",
},
{
  id: 3,
  name: "any time express",
  img: "anytime.png",
},
{
  id: 4,
  name: "sky express",
  img: "skyexpress.png",
},
{
  id: 5,
  name: "Merage express",
  img: "Merage.png",
},
{
  id: 6,
  name: "nova express",
  img: "nova.png",
},
{
  id: 7,
  name: "flash express",
  img: "flash.png",
},
{
  id: 8,
  name: "bebo fast express",
  img: "bebofast.png",
},
{
  id: 9,
  name: "turbo express",
  img: "turbo.png",
},
{
  id: 10,
  name: "magic express",
  img: "magic.png",
},
{
  id: 11,
  name: "3sare3 express",
  img: "3sare3.png",
},
{
  id: 12,
  name: "alnours express",
  img: "alnours.png",
},
{
  id: 14,
  name: "amana express",
  img: "amana.png",
},
{
  id: 15,
  name: "tayer express",
  img: "tayer.png",
},
{
  id: 16,
  name: "Door to Door ",
  img: "DoortoDoor.png",
},
{
  id: 17,
  name: "IB Courier express",
  img: "IBCourier.png",
},
{
  id: 18,
  name: "giriip express",
  img: "giriip.png",
},
{
  id: 19,
  name: "faster ex",
  img: "fasterex.png",
},
{
  id: 20,
  name: "bosla express",
  img: "bosla.png",
},
{
  id: 21,
  name: "Azzam express",
  img: "Azzam.png",
},
{
  id: 22,
  name: "thiqa express",
  img: "thiqa.png",
},
{
  id: 23,
  name: "Ibs Line express",
  img: "IBS.png",
},
{
  id: 24,
  name: "juka express",
  img: "juka.png",
},
{
  id: 25,
  name: "for you express",
  img: "foru.png",
},
{
  id: 26,
  name: "DAYER express",
  img: "DAYER.png",
},
{
  id: 27,
  name: "jaso express",
  img: "jaso.png",
},
{
  id: 28,
  name: "sabaa express",
  img: "sabaa.png",
},
{
  id: 29,
  name: "car go express",
  img: "cargo.png",
},
{
  id: 30,
  name: "m a express",
  img: "ma.png",
},
{
  id: 31,
  name: "infolx",
  img: "infolx.png",
},
{
  id: 32,
  name: "nexxt express",
  img: "nexxt.png",
},
{
  id: 33,
  name: "next express",
  img: "next.png",
},
{
  id: 34,
  name: "mars express",
  img: "mars.png",
},
{
  id: 35,
  name: "MAS SERV express",
  img: "MASSERV.png",
},
{
  id: 36,
  name: "Massa express",
  img: "Massa.png",
},
{
  id: 37,
  name: "on time ex",
  img: "ontimeex.png",
},
{
  id: 38,
  name: "on time",
  img: "ontime.png",
},
{
  id: 39,
  name: "Q express",
  img: "Qexpress.png",
},
{
  id: 40,
  name: "wasil express",
  img: "wasil.png",
},
{
  id: 41,
  name: "zedex",
  img: "zedex.png",
},
{
  id: 42,
  name: "tracking express",
  img: "tracking.png",
},
{
  id: 43,
  name: "Thunder express",
  img: "Thunder.png",
},
{
  id: 44,
  name: "A To Z",
  img: "AToZ.png",
},
{
  id: 45,
  name: "Move express.png",
  img: "Moveexpress.png",
},
{
  id: 46,
  name: "yedaiah",
  img: "yedaiah.png",
},
{
  id: 47,
  name: "bullet express",
  img: "bulletexpress.png",
},
{
  id: 48,
  name: "bullet",
  img: "bullet.png",
},
{
  id: 49,
  name: "bareedy express",
  img: "bareedy.png",
},
{
  id: 50,
  name: "eb3at express",
  img: "eb3at.png",
},
{
  id: 51,
  name: "EG",
  img: "EG.png",
},
{
  id: 52,
  name: "upex",
  img: "upex.png",
},
{
  id: 53,
  name: "Roadex",
  img: "Roadex.png",
},
{
  id: 54,
  name: "sahlah express",
  img: "sahlah.png",
},
{
  id: 55,
  name: "target",
  img: "target.png",
},
{
  id: 56,
  name: "onix",
  img: "onix.png",
},
{
  id: 57,
  name: "Dream express",
  img: "Dream.png",
},
{
  id: 58,
  name: "Pulse Group",
  img: "PulseGroup.png",
},
{
  id: 59,
  name: "ELRESALA",
  img: "ELRESALA.png",
},
{
  id: 60,
  name: "eltayar express",
  img: "eltayar.png",
},
{
  id: 61,
  name: "Yes express",
  img: "Yesexpress.png",
},
{
  id: 62,
  name: "bike express",
  img: "bike.png",
},
{
  id: 63,
  name: "Tak express",
  img: "Tak.png",
},
{
  id: 64,
  name: "elitex",
  img: "elitex.png",
},
{
  id: 65,
  name: "enveo",
  img: "enveo.png",
},
{
  id: 66,
  name: "magex",
  img: "magex.png",
},
{
  id: 67,
  name: "Team for shipping",
  img: "Teamforshipping.png",
},
{
  id: 68,
  name: "private group",
  img: "privategroup.png",
},
{
  id: 69,
  name: "a m y",
  img: "amy.png",
},
{
  id: 70,
  name: "care express",
  img: "care.png",
},
{
  id: 71,
  name: "MJK express",
  img: "MJK.png",
},
{
  id: 72,
  name: "TRAS",
  img: "TRAS.png",
},
{
  id: 73,
  name: "Troy express",
  img: "Troy.png",
},
{
  id: 74,
  name: "Vezba",
  img: "Vezba.png",
},
{
  id: 75,
  name: "vip express",
  img: "vip.png",
},
{
  id: 76,
  name: "Tawsseel express",
  img: "Tawsseel.png",
},
{
  id: 77,
  name: "ADM",
  img: "ADM.png",
},
{
  id: 78,
  name: "3M express",
  img: "3Mexpress.png",
},
{
  id: 79,
  name: "Shiply express",
  img: "Shiply.png",
},
{
  id: 80,
  name: "wolf express",
  img: "wolf.png",
},
{
  id: 81,
  name: "world shipping",
  img: "worldshipping.png",
},
{
  id: 82,
  name: "X Flex",
  img: "XFlex.png",
},
{
  id: 83,
  name: "Zwawe",
  img: "Zwawe.png",
},
{
  id: 84,
  name: "agead express",
  img: "agead.png",
},
{
  id: 85,
  name: "wafernalkom",
  img: "wafernalkom.png",
},
{
  id: 86,
  name: "Dash express",
  img: "Dash.png",
},
{
  id: 87,
  name: "deal",
  img: "deal.png",
},
{
  id: 88,
  name: "Mared",
  img: "Mared.png",
},
{
  id: 89,
  name: "JAM",
  img: "JAM.png",
},
{
  id: 90,
  name: "itex",
  img: "itex.png",
},
{
  id: 91,
  name: "sphinx",
  img: "sphinx.png",
},
{
  id: 92,
  name: "STS",
  img: "STS.png",
},
{
  id: 93,
  name: "shipper express",
  img: "shipper.png",
},
{
  id: 94,
  name: "pluto",
  img: "pluto.png",
},
{
  id: 95,
  name: "Eagels",
  img: "Eagels.png",
},
{
  id: 96,
  name: "FASTER",
  img: "FASTER.png",
},
{
  id: 97,
  name: "nibras",
  img: "nibras.png",
},
{
  id: 98,
  name: "Mustang",
  img: "Mustang.png",
},
{
  id: 99,
  name: "Molvoo express",
  img: "Molvoo.png",
},
{
  id: 100,
  name: "mgm",
  img: "mgm.png",
},
{
  id: 101,
  name: "Horizon",
  img: "Horizon.png",
},
{
  id: 102,
  name: "Guarantee",
  img: "Guarantee.png",
},
{
  id: 103,
  name: "Gallop express",
  img: "Gallopexpress.png",
},
{
  id: 104,
  name: "fly express",
  img: "fly.png",
},
{
  id: 105,
  name: "Ernest express",
  img: "Ernestexpress.png",
},
{
  id: 106,
  name: "Spedoo we",
  img: "Spedoo.png",
},
{
  id: 107,
  name: "sky express",
  img: "sky.png",
},
{
  id: 108,
  name: "aseel",
  img: "aseel.png",
},
{
  id: 109,
  name: "baitak",
  img: "baitak.png",
},
{
  id: 110,
  name: "black bird",
  img: "blackbird.png",
},
{
  id: 111,
  name: "bohairat",
  img: "bohairat.png",
},
{
  id: 112,
  name: "brand",
  img: "brand.png",
},
{
  id: 113,
  name: "dnadi",
  img: "dnadi.png",
},
{
  id: 114,
  name: "eagle",
  img: "eagle.png",
},
{
  id: 115,
  name: "easypass",
  img: "easypass.png",
},
{
  id: 116,
  name: "fursan",
  img: "fursan.png",
},
{
  id: 117,
  name: "galaxy",
  img: "galaxy.png",
},
{
  id: 118,
  name: "ghazala",
  img: "ghazala.png",
},
{
  id: 119,
  name: "hadarem",
  img: "hadarem.png",
},
{
  id: 120,
  name: "hadath",
  img: "hadath.png",
},
{
  id: 121,
  name: "HM",
  img: "HM.png",
},
{
  id: 122,
  name: "mersaly",
  img: "mirsaly.png",
},
{
  id: 123,
  name: "new age",
  img: "newage.png",
},
{
  id: 124,
  name: "MR",
  img: "MR.png",
},
{
  id: 125,
  name: "hassa",
  img: "hassa.png",
},
{
  id: 126,
  name: "holol",
  img: "holol.png",
},
{
  id: 127,
  name: "on timely",
  img: "ontimely.png",
},
{
  id: 128,
  name: "oqab",
  img: "oqab.png",
},
{
  id: 129,
  name: "wensh",
  img: "wensh.png",
},
{
  id: 130,
  name: "alex",
  img: "alex.png",
},
{
  id: 131,
  name: "dash",
  img: "dash1.png",
},
{
  id: 132,
  name: "cyrcle",
  img: "cyrcle.png",
},
{
  id: 133,
  name: "fasterbn",
  img: "fasterbn.png",
},
{
  id: 134,
  name: "ox",
  img: "ox.png",
},
{
  id: 135,
  name: "valid",
  img: "valid.png",
},
{
  id: 136,
  name: "vip",
  img: "vip2.png",
}
];