import React from "react";
// import { Link } from "react-router-dom";
import servicesTwoData from "./servicesTwoData";
import "./ServicesTwo.css";
import { useTranslation } from "react-i18next";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const ServicesTwo = () => {
  const { t, i18n } = useTranslation();
  console.log(i18n);
  return (
    <SectionWrapper BgColor={"#fafafa"} id="service-container">
      <section className="our-service-wrapper section-padding ">
        <div className="container">
          <div className="row g-3 servicesWrapper">
            {servicesTwoData.map((data) => (
              // <Link
              //   to={`/${data?.link}`}
              //   className="col-xl-4 col-md-6 col-12"
              //   key={data.id}
              // >
              <div className="col-xl-4 col-md-6 col-12">
                <div className="w-100 bg-white bo">
                  <img src={data.img} alt={data.title} width={"100%"} />
                  <div className="line"></div>
                  {/* <div className="line-two"></div> */}
                  <div
                    className={`${i18n.language === "ar"
                      ? "line-three-right"
                      : "line-three-left"
                      } line-three`}
                  ></div>
                  <div className="content">
                    {data.to ?
                      <ScrollLink
                        to={data.to}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="d-flex align-items-center"
                      >
                        <div className="icon-title d-flex p-3 justify-content-center">
                          <img
                            width={32}
                            src={require(`../../assets/img/icons/${data.icon}`)}
                            alt={data.title}
                          />
                          <div className="service-title px-3">
                            <h4 className="m-0 title">
                              {t(`services2.${data.title}`)}
                            </h4>
                          </div>
                        </div>
                      </ScrollLink> :
                      data.link ?
                        <Link to={`/${data?.link}`} >

                          <div className="icon-title d-flex p-3 justify-content-center">
                            <img
                              width={32}
                              src={require(`../../assets/img/icons/${data.icon}`)}
                              alt={data.title}
                            />
                            <div className="service-title px-3">
                              <h4 className="m-0 title">
                                {t(`services2.${data.title}`)}
                              </h4>
                            </div>
                          </div>
                        </Link> :
                        <div className="icon-title d-flex p-3 justify-content-center">
                          <img
                            width={32}
                            src={require(`../../assets/img/icons/${data.icon}`)}
                            alt={data.title}
                          />
                          <div className="service-title px-3">
                            <h4 className="m-0 title">
                              {t(`services2.${data.title}`)}
                            </h4>
                          </div>
                        </div>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section >
    </SectionWrapper >
  );
};

export default ServicesTwo;
