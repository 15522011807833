import React, { useState } from "react";
// import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import SectionWrapper from "../CustmComponent/SectionWrapper";
import { FloatingLabel, Spinner } from "react-bootstrap";
// import "./contactUs.css";
import $ from "jquery";
import { MdWorkOutline } from "react-icons/md";
import { IoIosCreate } from "react-icons/io";
import { FaPhp, FaReact } from "react-icons/fa";
import { CiDatabase } from "react-icons/ci";
import { IoCreateOutline } from "react-icons/io5";

const Jobs = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const data = [
    {
      id: 0,
      title: "Oracle Apex developer",
      info:
        [
          "Required skills :",
          " - +1 Experience with Oracle SQL and PL/SQL.",
          " - JavaScript, HTML, CSS, ajax"
        ],
      icon: CiDatabase,
    },
    {
      id: 1,
      title: "PHP developer",
      info:
        [
          "Required skills :",
          " - +2 with PHP with strong Object Oriented programming skills",
          " - Strong Laravel development knowledge",
          " - Comfortable working with Linux",
          " - Good knowledge of version control tools like GIT",
          " - GraphQL web services knowledge"
        ],

      icon: FaPhp,
    },
    {
      id: 2,
      title: "Frontend Developer",
      info:
        [
          "Required skills :",
          " - +1 experience in front-end software development using React.",
          " - Strong in CSS, HTML and web application development knowledge.",
          " - Experience working in an Agile development team."
        ],

      icon: FaReact,
    },
    {
      id: 3,
      title: "Media buyer",
      info:
        [
          "Required skills :",
          " - +1 Experience working platform (tiktok _Facebook _twitter _Snapchat _LinkedIn _...)industry",
          " - Experience working with Google ads"
        ],

      icon: IoCreateOutline,
    },
  ];

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    cvFile: null,
  });

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState({
    message: "",
    type: "",
  });

  // const [validation, setValidation] = useState(false);



  const openDialog = (data) => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      cvFile: file,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }

    form.classList.add('was-validated');

    // setValidation(true);
    // setShowDialog(true);
    // setIsLoading(true);
    setFormData({
      name: '',
      address: '',
      phone: '',
      email: '',
      cvFile: null,
    })
    // setShowDialog(false);
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   setIsLoading(false);
    //   e.preventDefault();
    //   e.stopPropagation();
    // } else {
    //   $.post({
    //     url: "mail.php",
    //     data: formData,
    //   }).always(function (data) {
    //     setIsLoading(false);
    //     setFormMessage({
    //       _message: "messageSentSuccess",
    //       get message() {
    //         return this._message;
    //       },
    //       set message(value) {
    //         this._message = value;
    //       },
    //       type: "success",
    //     });
    //     setFormData({
    //       name: "",
    //       email: "",
    //       body: "",
    //       phone: "",
    //       company: "",
    //     })
    //   })
    // }
  }

  const dialog =
    <div class="modal-body">
      <div class="container-fluid">
        <form onSubmit={handleSubmit} noValidate className="needs-validation">
          <div className="mb-3 ">
            <label htmlFor="name" className="form-label">Name</label>
            <input
              type="text"
              className={`form-control form-control-lg text-start`}
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              
            />
          </div>
          <div className="mb-3">
            <label htmlFor="address" className="form-label">Address</label>
            <input
              type="text"
              className="form-control form-control-lg text-start"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input
              type="text"
              className="form-control form-control-lg text-start"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control form-control-lg text-start"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="cvFile" className="form-label">CV File</label>
            <input
              style={{ direction: "ltr" }}
              type="file"
              className="form-control form-control-lg"
              id="cvFile"
              name="cvFile"
              onChange={handleFileChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>

  return (
    <SectionWrapper
      BgColor={"#fff"}
      title={t("jobs")}
      subTitle={t("join us")}
    >
      {showDialog && (
        <div className={`modal fs-5 text-start`} style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content" style={{backgroundColor:"#f1efef" }}>
              <div className="modal-header" style={{ direction: "ltr" }}>
                <h5 className="modal-title">apply here</h5>
                <button type="button" className={`btn-close m-0`} onClick={closeDialog}></button>
              </div>
              {dialog}
            </div>
          </div>
        </div>
      )}
      <div className="container ">
        <div className="row g-3 d-flex justify-content-around">
          {data.map((item) => (
            <div className="col-7 p-4 ps-5"
              style={{
                backgroundColor: "#fafafa", borderRadius: "30px", direction: "ltr", border: `2px solid var(--primaryColor)`, position: "relative"
              }}>
              <div className="icon d-flex align-items-center justify-content-center" style={{ fontSize: "50px", position: "absolute", top: "50%", transform: "translateY(-50%)", left: "-35px", backgroundColor: "var(--primaryColor)", borderRadius: "50%", padding: "5px", width: "70px", height: "70px", color: "#fff" }} >
                <item.icon />
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className={`info fs-4 text-start`} >{item.title}</h4>
                  {item.info.map((l, i) => (
                    <div
                      key={i}
                      className={`info fs-6 text-start`}
                      style={{ direction: "ltr" }}
                    >
                      {t(l)}
                    </div>
                  ))}
                </div>
                <div className="align-self-end">
                  <button type="button" class="btn btn-primary btn-sm px-4 py-2 " onClick={() => openDialog(data)}>apply</button>
                </div>
              </div>
            </div>))}
          {/* <div className="col-6 ">
        </div>
        <div className="col-6  " style={{ backgroundColor: "#fafafa", borderRadius: "30px" }}></div> */}
        </div>
        {/* <div className="contact-us-sidebar mt-5 w-75 text-center">
            <div className="contact-info">
              
              {data.map((el) => (
                <div
                  className="col-md-10 col-12 mx-2 py-4 d-flex justify-content-center mt-5 "
                  style={{ backgroundColor: "#fafafa", borderRadius: "30px" }}
                >
                  <div
                    className="single-info row justify-content-center mb-4 align-items-center w-75 d-flex"
                    key={el.id}
                    style={{ direction: "ltr" }}
                  >
                    <div className="icon col-2">
                      <el.icon style={{ fontSize: "40px" }} />
                    </div>

                    <div className="text col-10">
                      <h4 className={`info fs-5 text-start`} >{el.title}</h4>
                      {el.info.map((l, i) => (
                        <div
                          key={i}
                          className={`info fs-5 text-start`}
                          style={{ direction: "ltr" }}
                        >
                          {t(l)}
                        </div>
                      ))}
                      <button className="btn btn-primary mt-3" onClick={() => openDialog(data)}>apply here </button>

                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}


      </div>
    </SectionWrapper>
  );
};

export default Jobs;

