import { AiOutlineWhatsApp } from "react-icons/ai";
import { BiBarcodeReader, BiPhoneOutgoing } from "react-icons/bi";
import { BsCart3, BsChatDots, BsDistributeVertical } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { IoIosGitBranch, IoMdNotificationsOutline } from "react-icons/io";
import { MdAppRegistration, MdAttachMoney, MdOutlineAccountCircle, MdOutlineColorLens, MdOutlineIntegrationInstructions, MdOutlineInventory, MdOutlineManageAccounts, MdScreenSearchDesktop } from "react-icons/md";
import { RiChatFollowUpLine, RiEBike2Line, RiRoadMapLine } from "react-icons/ri";
import { VscPackage } from "react-icons/vsc";
import { LiaMoneyBillSolid, LiaWarehouseSolid } from "react-icons/lia";
import { GiFlexibleStar } from "react-icons/gi";
import { SiSimpleanalytics } from "react-icons/si";
import { CiCreditCard1, CiMoneyCheck1 } from "react-icons/ci";
import { TbReportAnalytics } from "react-icons/tb";

export const erpAttribute2 = [
    {
        icon: MdAppRegistration,
    },
    {
        icon: LiaWarehouseSolid,
    },
    {
        icon: LiaWarehouseSolid,
    },
    {
        icon: MdOutlineManageAccounts,
    },
    {
        icon: MdOutlineColorLens,
    },
    {
        icon: MdAttachMoney,
    },
    {
      icon: GiFlexibleStar,
    },
    {
      icon: SiSimpleanalytics,
    },
    {
      icon: CiCreditCard1,
    },
    {
      icon: MdOutlineInventory,
    },
    {
      icon: BsDistributeVertical,
    }
]
export const erpAttribute3 = [
    {
        icon: FiUsers,
    },
    {
        icon: IoIosGitBranch,
    },
    {
        icon: TbReportAnalytics,
    },
    {
        icon: TbReportAnalytics,
    },
    {
        icon: TbReportAnalytics,
    },
    {
        icon: LiaMoneyBillSolid,
    },
    {
      icon: TbReportAnalytics,
    },
    {
      icon: IoMdNotificationsOutline,
    },
    {
      icon: MdOutlineAccountCircle,
    },
    {
      icon: MdScreenSearchDesktop,
    },
    {
      icon: TbReportAnalytics,
    },
    {
      icon: TbReportAnalytics,
    }
]
export const erpAttribute4 = [
    {
        icon: FiUsers,
    },
    {
        icon: RiEBike2Line,
    },
    {
        icon: TbReportAnalytics,
    },
    {
        icon: TbReportAnalytics,
    },
    {
        icon: VscPackage,
    },
    {
        icon: TbReportAnalytics,
    },
    {
      icon: TbReportAnalytics,
    },
    {
      icon: TbReportAnalytics,
    },
    {
      icon: MdScreenSearchDesktop,
    },
    {
      icon: MdScreenSearchDesktop,
    },
    {
      icon: TbReportAnalytics,
    },
    {
      icon: TbReportAnalytics,
    }
]
export const erpAttribute5 = [
    {
        icon: TbReportAnalytics,
    },
    {
        icon: TbReportAnalytics,
    },
    {
        icon: TbReportAnalytics,
    },
    {
        icon: CiMoneyCheck1,
    },
    {
        icon: RiChatFollowUpLine,
    },
    {
        icon: LiaMoneyBillSolid,
    },
    {
      icon: LiaMoneyBillSolid,
    },
    {
      icon: MdScreenSearchDesktop,
    }
]
export const erpAttribute6 = [
    {
        icon: FiUsers,
    },
    {
        icon: RiEBike2Line,
    },
    {
        icon: VscPackage,
    },
    {
        icon: RiRoadMapLine,
    },
    {
        icon: AiOutlineWhatsApp,
    },
    {
        icon: IoMdNotificationsOutline,
    }
]
export const erpAttribute7 = [
    {
        icon: FiUsers,
    },
    {
        icon: RiEBike2Line,
    },
    {
        icon: VscPackage,
    },
    {
        icon: RiRoadMapLine,
    },
    {
        icon: AiOutlineWhatsApp,
    },
    {
        icon: IoMdNotificationsOutline,
    },{
      icon: FiUsers,
  },
  {
      icon: RiEBike2Line,
  },
  {
      icon: VscPackage,
  },
  {
      icon: RiRoadMapLine,
  }
]