import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import Button from "react-bootstrap/Button";
import SectionWrapper from "../CustmComponent/SectionWrapper";
// import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./contactUs.css";
import $ from "jquery";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
// import PhoneInput from 'react-phone-number-input'
import "react-phone-number-input/style.css";
// import countryList from "react-select-country-list";
import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import MuiPhone from "./MUIphoneNumber";
import { Controller, useForm } from "react-hook-form";
import { getFormatNumber, validNumber } from "./asYouType";

const ContactPageContents = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const theme = createTheme({
    direction: lang === "ar" ? "rtl" : "ltr",
    typography: {
      fontFamily: [`cairo`, "sans-serif"].join(","),
      // fontSize: 12.5,
    },
    // TextField: {
    //   fontFamily: [`cairo`, "sans-serif"].join(","),
    //   // fontSize: 12.5,
    // },
  });

  const muiCache = createCache({
    key: "mui",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm();
  const { errors } = formState;

  const data = [
    {
      id: 10,
      info: ["info@accuratess.com"],
      icon: "flaticon-email",
    },
    {
      id: 11,
      info: ["+201061802245", "+201063825242"],
      icon: "flaticon-phone-call-1",
    },
    {
      id: 12,
      info: ["companyAddress"],
      icon: "flaticon-pin",
      address: "https://maps.app.goo.gl/2R5WX7WH6LyaewkE9",
    },
  ];
  // const options = countryList().getData();

  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState({
    message: "",
    type: "",
  });

  const onSubmit = async (data) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { email } = data;

    if (!emailPattern.test(email)) {
      setError("email", {
        type: "pattern",
        message: t("invalidEmailAddress"),
      });
    } else {
      clearErrors("email"); // Clear the error if the email is valid
    }
    const phone = validNumber(data.phone, data.phoneCode);
    let names = [
      {
        name: "phone",
        validate: phone.valid,
        message: phone.message,
      },
    ];
    if (!phone.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }
    data.phone = getFormatNumber(data.phone, data.phoneCode);

    for (const key in data) {
      if (data[key] === "" || key === "phoneCode") {
        delete data[key];
      }
    }
    setIsLoading(true);
    $.post({
      url: "mail.php",
      data: data,
    })
      .done(function(data) {
        setIsLoading(false);
        setFormMessage({ message: "messageSentSuccess", type: "success" });
        setValue("name", "");
        setValue("email", "");
        setValue("body", "");
        setValue("phone", "");
        setValue("company", "");
        // setValue("country", "");
      })
      .fail(function(xhr, status, error) {
        setIsLoading(false);
        setFormMessage({
          message: "messageSentError",
          type: "error",
        });
      });
  };

  return (
    <SectionWrapper
      BgColor={"#fff"}
      title={t("Contact")}
      subTitle={t("about2.title")}
    >
      <div className="container">
        <div className="row g-3 justify-content-center">
          <div
            className="col-md-7 col-12 p-4 mx-2"
            style={{ backgroundColor: "#fafafa", borderRadius: "30px" }}
          >
            <div className="contact-form">
              <h2>{t("footer2.h5")}</h2>
              <CacheProvider value={lang === "ar" ? cacheRtl : muiCache}>
                <ThemeProvider
                  theme={
                    lang === "ar"
                      ? {
                          ...theme,
                          direction: "rtl",
                        }
                      : {
                          ...theme,
                          direction: "ltr",
                        }
                  }
                >
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          rules={{ required: t("fieldIsRequired") }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t("name")}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              error={!!errors.name}
                              helperText={
                                errors.name ? errors.name.message : ""
                              }
                              InputLabelProps={
                                {
                                  // style: {
                                  //   fontFamily: "Cairo",
                                  //   fontSize: "14px",
                                  //   position: "absolute",
                                  //   right: "-3px",
                                  // },
                                }
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        // direction={lang === "ar" ? "rtl" : "ltr"}
                      >
                        <Controller
                          name="company"
                          control={control}
                          defaultValue=""
                          rules={{ required: t("fieldIsRequired") }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t("company")}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              error={!!errors.company}
                              helperText={
                                errors.company ? errors.company.message : ""
                              }
                              InputLabelProps={
                                {
                                  // style: {
                                  //   fontFamily: "Cairo",
                                  //   fontSize: "14px",
                                  //   position: "absolute",
                                  //   right: "-3px",
                                  // },
                                }
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} aligns="flex-start">
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: t("fieldIsRequired"),
                            // pattern: {
                            //   value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            //   message: "Invalid email address",
                            // },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t("email")}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              error={!!errors.email}
                              helperText={
                                errors.email ? (
                                  <span className="error-message">
                                    {errors.email.message}
                                  </span>
                                ) : (
                                  ""
                                )
                              }
                              InputLabelProps={
                                {
                                  // style: {
                                  //   fontFamily: "Cairo",
                                  //   fontSize: "14px",
                                  //   position: "absolute",
                                  //   right: "-3px",
                                  // },
                                }
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MuiPhone
                          setValue={setValue}
                          codeValue={getValues().phoneCode}
                          rules={{ required: t("fieldIsRequired") }}
                          control={control}
                          errors={errors}
                          name={"phone"}
                          selectName={"phoneCode"}
                          label={t("phoneNumber")}
                          InputLabelProps={
                            {
                              // style: {
                              //   fontFamily: "Cairo",
                              //   fontSize: "14px",
                              //   position: "absolute",
                              //   right: "-4px",
                              // },
                            }
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="body"
                          control={control}
                          defaultValue=""
                          rules={{ required: t("fieldIsRequired") }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t("subject")}
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              multiline
                              rows={4}
                              error={!!errors.body}
                              helperText={
                                errors.body ? errors.body.message : ""
                              }
                              InputLabelProps={
                                {
                                  // style: {
                                  //   fontFamily: "Cairo",
                                  //   fontSize: "14px",
                                  //   position: "absolute",
                                  //   right: "-2px",
                                  // },
                                }
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="error"
                        disabled={isLoading}
                        sx={{ fontFamily: "cairo" }}
                      >
                        {t("send")}
                        {isLoading && (
                          <CircularProgress size={24} sx={{ ml: 2 }} />
                        )}
                      </Button>
                      {formMessage.message && (
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "cairo",
                            ml: 3,
                            textTransform: "capitalize",
                            color:
                              formMessage.type === "error"
                                ? "error.main"
                                : "success.main",
                          }}
                        >
                          {t(formMessage.message)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </ThemeProvider>
              </CacheProvider>
            </div>
          </div>
          <div
            className="col-md-4 col-12 mx-2 py-4 d-flex justify-content-center"
            style={{ backgroundColor: "#fafafa", borderRadius: "30px" }}
          >
            <div className="contact-us-sidebar mt-5 mt-lg-0 text-center">
              <div className="contact-info">
                <h2 className="mb-5">{t("contact2.contact2")}</h2>
                {data.map((el) => (
                  <div
                    className="single-info row justify-content-center mb-4 align-items-center"
                    key={el?.id}
                  >
                    <div className="icon col-2">
                      <i className={el.icon} />
                    </div>
                    {el?.address ? (
                      <div className="text col-10">
                        <a
                          href={el.address}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {el.info.map((l, i) => (
                            <div key={i}>
                              <div
                                className={`info fs-5 pb-3 ${
                                  lang === "en" ? "text-start" : "text-end"
                                }`}
                                style={{ direction: "ltr" }}
                              >
                                {t(l)}
                              </div>
                              <div>
                                <iframe
                                  title="My Location"
                                  width="300"
                                  height="120"
                                  style={{ border: 0 }}
                                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.3587796297415!2d31.183117299999996!3d30.4825953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f7df7ef6214beb%3A0xd48f84aab6436d19!2z2KPZg9mK2YjYsdmK2Kog2YTZhNit2YTZiNmEINin2YTYsNmD2YrYqQ!5e0!3m2!1sen!2seg!4v1709020026005!5m2!1sen!2seg"
                                  loading="lazy"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            </div>
                          ))}
                        </a>
                      </div>
                    ) : (
                      <>
                        <div className="text col-10">
                          {el.info.map((l, i) => (
                            <div
                              key={i}
                              className={`info fs-5 ${
                                lang === "en" ? "text-start" : "text-end"
                              }`}
                              style={{ direction: "ltr" }}
                            >
                              {l === "info@accuratess.com" ? (
                                <a href={`mailto:${l}`}>{l}</a>
                              ) : (
                                <a href={`tel:${l}`}>{l}</a>
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ContactPageContents;
