import React from 'react'
import ReactLoading from "react-loading";
import logoAcc from "../assets/img/logoAcc.png"
const Preloader = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <ReactLoading  type={"bars"} color={"var(--primaryColor)"}  width={"100px"} />
          
            
          
        </div>
    )
}

export default Preloader