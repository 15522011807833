import cashier from "../../assets/img/services/Code-typing-rafiki.svg";
import data from "../../assets/img/services/Website-designer-pana.svg";
import mobileApp from "../../assets/img/services/Mobile-inbox-pana.svg";
import website from "../../assets/img/Website Creator.gif";
import mobile from "../../assets/img/App monetization.gif";
import software from "../../assets/img/Open source.gif";

const blogOneData = [
  {
    id: "website",

    authorImg: data,
    img: website,
  },
  {
    id: "mobile",

    authorImg: mobileApp,
    img: mobile,
  },
  {
    id: "software",

    authorImg: cashier,
    img: software,
  },
];

export default blogOneData;
