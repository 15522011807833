import React, { useEffect, useState } from "react";
import mainLogo from "../../assets/img/footer-logo.png";
import { Link } from "react-router-dom";
// import MobileMenu from "./MobileMenu";
import "./header.css";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { style } from "typestyle";
import LangButton from "../langButton";
import { FadeIn } from "react-slide-fade-in";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BiMenu } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import Collapse from "react-bootstrap/Collapse";
import { AiOutlineMinus } from "react-icons/ai";
import { Link as ScrollLink } from "react-scroll";
// import AnimationPage from "../CustmComponent/AnimationPage";

export const liHeader = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "About",
    // link: "/about",
    link: "/aboutPage",
  },
  {
    id: 3,
    name: "Services",
    link: "/services",
    // to: "services-container",
    // subChildern: [
    //   {
    //     id: 1,
    //     name: "Website Design",
    //     // link: "/ServiceView/1",
    //     to: "services-container",
    //   },
    //   {
    //     id: 2,
    //     name: "Mobile Application",
    //     to: "services-container",
    //     // link: "/ServiceView/2",
    //   },
    //   {
    //     id: 3,
    //     name: "Software Design",
    //     to: "services-container",
    //     // link: "/ServiceView/3",
    //   },
    // ],
  },
  {
    id: 4,
    name: "Projects",
    link: "/projects",
    to: "projects-container",
    subChildern: [
      {
        id: 1,
        name: "Logistic Systems",
        link: "/apps/accurate-logistics",
      },
      {
        id: 2,
        name: "Accurate Erp",
        to: "projects-container",
        link: "/apps/accurate-erp",
      },
      // {
      //   id: 3,
      //   name: "private schools",
      //   // link: "/projectDetails/privateschools",
      //   // to: "projects-container",
      // },
      // {
      //   id: 4,
      //   name: "AccUrate pos",
      //   // link: "/projectDetails/AccUratepos",
      //   // to: "projects-container",
      // },
      // {
      //   id: 5,
      //   name: "Restaurants",
      //   // link: "/projectDetails/AccurateRestaurants",
      //   // to: "projects-container",
      // },
    ],
  },
  {
    id: 5,
    name: "Clients",
    link: "/clients",
    // to: "clients-container",
  },
  // {
  //   id: 6,
  //   name: "jobs",
  //   link: "/jobs",
  // },
  {
    id: 7,
    name: "Contact",
    link: "/contact",
  },
];

const Header = ({ languagechange }) => {
  const headerWrapper = style({
    // $nest: {
    //   "&:hover": {
    //     color: "white",
    //   },
    // },
    display: "flex",
    justifyContent: "space-between",
  });
  const stickyHeader = style({
    position: "sticky",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 100,
    transition: "all 1s",
    backgroundColor: "#fff",
    color: "#fff",
    animationName: "$animationFade",
    animationDuration: "1s",
    animationFillMode: "both",
  });
  const rotateEN = style({
    transform: "rotate(90deg)",
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState({
    Services: false,
    Projects: false,
  });
  const [dropDownOpen, setDropDownOpen] = useState({
    Services: false,
    Projects: false,
  });

  // const [dropDownOpen, setdropDownOpen] = useState(false);

  const closeAllTabs = () => {
    setShow(false);
    setOpen({
      Services: false,
      Projects: false,
    });
  };

  const [shouldShowHeader, setShouldShowHeader] = useState(false);
  const listenToScroll = () => {
    setShouldShowHeader(window.pageYOffset > 300);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const navLinks = () => {
    return windowWidth > 900 ? (
      <div className="linksWrapper d-flex">
        {liHeader.map((l, i) =>
          l.subChildern ? (
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={t(l.name)}
              menuVariant="light"
              open={dropDownOpen[l.name]}
              key={l.id}
              onClick={() => {
                setDropDownOpen((prev) => ({
                  ...prev,
                  [l.name]: !prev[l.name],
                }));
              }}
            >
              {l.subChildern.map((el) => (
                <NavDropdown.Item
                  as={"div"}
                  className="p-0"
                  key={el.id}
                  onClick={() => {
                    setDropDownOpen((prev) => ({
                      ...prev,
                      [l.name]: !prev[l.name],
                    }));
                  }}
                >
                  {el.link ? (
                    <Link to={el.link} className="dropDownLinks px-2">
                      {t(el.name)}
                    </Link>
                  ) : (
                    <ScrollLink
                      to={el.to}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      className="dropDownLinks px-2"
                      onClick={() => {
                        setDropDownOpen((prev) => ({
                          ...prev,
                          [l.name]: !prev[l.name],
                        }));
                      }}
                    >
                      {t(el.name)}
                    </ScrollLink>
                  )}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          ) : l.link ? (
            <Link to={l.link} key={l.id} className="d-flex align-items-center">
              {t(l.name)}
            </Link>
          ) : (
            <ScrollLink
              to={l.to}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="d-flex align-items-center"
            >
              {t(l.name)}
            </ScrollLink>
          )
        )}
      </div>
    ) : (
      <Offcanvas
        show={show}
        onHide={closeAllTabs}
        placement={"top"}
        className="OffcanvasHeader"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src={mainLogo} alt="logo" width={150} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show &&
            liHeader.map((l, i) => (
              <FadeIn
                key={i}
                from="top"
                positionOffset={400}
                triggerOffset={1000}
                delayInMilliseconds={i * 5}
              >
                {l.subChildern ? (
                  <>
                    <div
                      className="CollapseLink d-flex justify-content-between align-items-center"
                      onClick={(e) =>
                        setOpen((prev) => ({
                          ...prev,
                          [l.name]: !prev[l.name],
                        }))
                      }
                      aria-controls={l.name}
                      aria-expanded={open[l.name]}
                    >
                      <span>{t(l.name)}</span>
                      {open[l.name] ? (
                        <IoIosArrowBack
                          style={{
                            fontSize: "19px",
                            margin: " 0 6px",
                            transform: `${
                              i18n.language === "en" ? "rotate(180deg)" : "0"
                            }`,
                          }}
                        />
                      ) : (
                        <IoIosArrowDown
                          style={{ fontSize: "19px", margin: " 0 6px" }}
                        />
                      )}
                    </div>
                    <Collapse in={open[l.name]}>
                      <div id={l.name}>
                        <ul class="list-group mt-3">
                          {l.subChildern.map((el) => (
                            <li class="p-0" key={el.id} onClick={closeAllTabs}>
                              <Link
                                to={el.link}
                                className="d-flex p-2 align-items-center"
                              >
                                <AiOutlineMinus
                                  style={{ fontSize: "11px", margin: "0 8px" }}
                                />
                                {t(el.name)}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Collapse>
                  </>
                ) : l.link ? (
                  <Link to={l.link} key={l.id} className="d-flex "   onClick={closeAllTabs}>
                    {t(l.name)}
                  </Link>
                ) : (
                  <ScrollLink
                    to={l.to}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="d-flex"
                    onClick={closeAllTabs}
                  >
                    {t(l.name)}
                  </ScrollLink>
                )}
              </FadeIn>
            ))}
        </Offcanvas.Body>
      </Offcanvas>
    );
  };
  return (
    // <AnimationPage>
    <header
      className={`py-2 headerContainer ${shouldShowHeader && stickyHeader}`}
    >
      <Container>
        <div className={headerWrapper}>
          <div className="logo">
            <Link to="/">
              <img src={mainLogo} alt="logo" width={200} />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            {navLinks()}
            {windowWidth <= 900 && (
              <div onClick={handleShow} className="dropDownIcon">
                <BiMenu
                  style={{ fontSize: "30px", color: "while" }}
                />
              </div>
            )}
            <LangButton languagechange={languagechange} />
          </div>
        </div>
      </Container>
    </header>
  // </AnimationPage>
  );
};

export default Header;
