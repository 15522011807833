import React from "react";
import Header from "../Components/Header/Header";
import FooterTwo from "../Components/Footer/FooterTwo";
import { Outlet } from "react-router-dom";

export default function Layout({ languagechange }) {

    return (
        <div>
            <Header languagechange={languagechange} />
            <Outlet />
            <FooterTwo />
        </div>
    );
}
