import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Preloader from "./Components/Preloader";
import ScrollTopBtn from "./Components/ScrollTopBtn";
import HomeTwo from "./pages/HomeTwo";
import ContactPage from "./pages/ContactPage";
import Header from "./Components/Header/Header";
import "./assets/css/cardStyle.css";
import "./assets/css/buttonStyles.css";
import ScrollToTop from "./Components/ScrollToTop";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import arTranslation from "./assets/Locals/Ar.json";
import enTranslation from "./assets/Locals/En.json";
// import Whatsapp from "./Components/Whatsapp";
import config from "./config.json";
import NotFound from "./Components/NotFound/NotFound";
import FooterTwo from "./Components/Footer/FooterTwo";
// import Container from "react-bootstrap/Container";
import AboutPage from "./Components/AboutPage/AboutPage";
import AccurateLogistics from "./Components/AccurateLogistics/AccurateLogistics";
import { ServicePage } from "./Components/Service/ServicePage";
import { AccurateErp } from "./Components/AccurateErp/AccurateErp";
import Jobs from "./pages/Jobs";
import { Clients } from "./Components/Clients/Clients";
import { ClintsPage } from "./pages/Clints";
import Contact from "./pages/Contact";
import Layout from "./pages/Layout";
// import withRoot from "./withRoot";
function App(props) {
  const [loading, setLoading] = useState(true);

  !localStorage.getItem("language") &&
    localStorage.setItem("language", config.lang);
  const [change, setchang] = useState(localStorage.getItem("language"));

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    // Initialize i18next
    if (change === "ar") {
      document.body.dir = "rtl";
      i18n.use(initReactI18next).init({
        lng: "ar",
        resources: {
          ar: {
            translation: arTranslation,
          },
        },
        fallbackLng: "ar",
        debug: true,
        interpolation: {
          escapeValue: false,
        },
      });
    } else {
      document.body.dir = "ltr";
      i18n.use(initReactI18next).init({
        lng: "en",
        resources: {
          en: {
            translation: enTranslation,
          },
        },
        fallbackLng: "en",
        debug: true,
        interpolation: {
          escapeValue: false,
        },
      });
    }
  }, [change]);

  function languagechange(e) {
    localStorage.setItem("language", e);
    setchang(e);
  }

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <>
        <I18nextProvider i18n={i18n}>
          <ScrollTopBtn />
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={<Layout languagechange={languagechange} />}
            >
              <Route index element={<HomeTwo />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/aboutPage" element={<AboutPage />} />
              <Route path="/services" element={<ServicePage />} />
              <Route path="/clients" element={<ClintsPage />} />
              <Route
                path="/apps/accurate-logistics"
                element={<AccurateLogistics />}
              />
              <Route path="/apps/accurate-erp" element={<AccurateErp />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/contactMe" element={<Contact />} />
          </Routes>
        </I18nextProvider>
      </>
    );
  }
}

export default App;
