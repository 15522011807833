import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.css";
import "./assets/css/icons.css";
import "./assets/css/style.css";
import "../node_modules/bootstrap/dist/js/bootstrap";

// import "bootstrap-rtl/dist/css/bootstrap-rtl.css";
// import SimpleReactLightbox from 'simple-react-lightbox';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter basename="/">
    {/* <Suspense fallback={""}> */}
    {/* <ScrollToTop> */}
    <App />
    {/* </ScrollToTop> */}
    {/* </Suspense> */}
  </BrowserRouter>
);
// ReactDOM.render(
//     <>
//     {/* <SimpleReactLightbox> */}
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//       {/* </SimpleReactLightbox> */}
//     </>,
//     document.getElementById('root')
//   );
reportWebVitals();
