import { v4 as uuidv4 } from "uuid";
import img1 from "../../assets/img/home2/s-300x200.jpg";
import img2 from "../../assets/img/home2/s2.jpg";
import img3 from "../../assets/img/home2/s-300x200.jpg";

const servicesTwoData = [
  {
    id: uuidv4(),
    img: img1,
    icon: "application.png",
    title: "serviceTitle1",
    desc: "serviceDesc1",
    // link: "#projects",
    to: "projects-container"
  },

  {
    id: uuidv4(),
    img: img2,
    icon: "digital-services.png",
    title: "serviceTitle2",
    desc: "serviceDesc2",
    link: "services",
  },

  {
    id: uuidv4(),
    img: img3,
    icon: "currency.png",
    title: "serviceTitle3",
    desc: "serviceDesc3",
    // link: "offers",
  },
];

export default servicesTwoData;
