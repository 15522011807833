import React from "react";
import { Link } from "react-router-dom";
// import FooterCta from "./FooterCta";
import FooterOneCopyrights from "./FooterOneCopyrights";
import itida from "../../assets/img/itida.jpeg";
import { useTranslation } from "react-i18next";
import footerLogo from "../../assets/img/footer-logo.png";
import { liHeader } from "../Header/Header";
import { GrFacebookOption } from "react-icons/gr";
import { PiMessengerLogoBold } from "react-icons/pi";
import { FaWhatsapp, FaInstagram, FaLinkedinIn,FaTwitter ,FaYoutube  } from "react-icons/fa";
import './style.css'

const FooterTwo = () => {
  const { t } = useTranslation();

  const socialData = [
    {
      id: 0,
      link: "https://www.facebook.com/AccurateSmartSolutions/",
      color: "#3b5998",
      onHoverColor: "red",
      icon: GrFacebookOption
    },
    {
      id: 1,
      link: "https://m.me/245399558968790",
      color: "#0099FF",
      onHoverColor: "#0099FF",
      icon: PiMessengerLogoBold
    },
    {
      id: 2,
      link: "https://wa.me/+201063825242",
      color: "#28D146",
      onHoverColor: "red",
      icon: FaWhatsapp
    },
    {
      id: 3,
      link: "https://www.instagram.com/accurate_smart_solutions",
      color: "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      onHoverColor: "red",
      icon: FaInstagram
    },
    {
      id: 4,
      link: "https://twitter.com/AccUrate_ss",
      color: "#0072b1",
      onHoverColor: "#0072b1",
      icon: FaTwitter
    },
    {
      id: 5,
      link: "https://www.linkedin.com/company/accurate-smart-solutions/",
      color: "#0072b1",
      onHoverColor: "#0072b1",
      icon: FaLinkedinIn
    },
    {
      id: 6,
      link: "https://www.youtube.com/@AccurateSmartSolutions",
      color: "#0072b1",
      onHoverColor: "#0072b1",
      icon: FaYoutube
    }
  ]

  return (
    <footer className="d-flex flex-column justify-content-center align-items-center bg-primary py-4">
      <div className="footer-logo mb-3">
        <img src={footerLogo} alt="logo" height={"60px"} />
      </div>
      <div className="d-flex mb-4 flex-wrap justify-content-center">
        {liHeader.map((ele) =>
          <div key={ele.name}>
            <Link
              className="px-2 d-flex align-items-center text-white"
              to={ele.link}
            >
              {t(ele.name)}
            </Link>
          </div>
        )}
      </div>
      <div className="mb-3">
        <h3 className="m-0 text-white">{t("footer2.stayInTouch")}</h3>
      </div>
      <div className="socialWrapper">
        {socialData.map((ele) =>
          <a
            href={ele.link}
            target="_blank"
            rel="noopener noreferrer"
            key={ele.id}
            // style={{
            //   background: `${ele.color}`
            //   // background: ""
            // }}
          >
            <ele.icon style={{ fontSize: "20px" }}/>
          </a>
        )}
      </div>
    </footer>
  );
};

export default FooterTwo;
