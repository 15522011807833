import React from "react";
import { useTranslation } from "react-i18next";
import PannerCompant from "../CustmComponent/PannerCompant";
import panner from "../../assets/img/panner1.svg";
import pannerImage from "../../assets/img/aboutPanner.webp";

const PannerSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <PannerCompant
        BgImage={panner}
        SideImage={pannerImage}
        title={t("aboutPage.pannerAboutTitle")}
        subTitle={t("aboutPage.pannerAboutBody")}
        id="About"
      />
    </>
  );
};

export default PannerSection;