import React from "react";
import PropTypes from "prop-types";
import "./style.css";

function CustomLinkText(props) {
  const { text } = props;

  return <p className="CustomLinkText title">{text}</p>;
}

CustomLinkText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CustomLinkText;
